import Badge from "./Badge/Badge";

const File = (props) => {
  return (
    <ul className="file-container-warning">
      {props.data.data.map((item) => {
        return <Badge color={props.color} key={item.id} data={item} />;
      })}
    </ul>
  );
};
export default File;
