import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Login from "../Login/Login";
import Register from "../Register/Register";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const MozartDialog = ({ setClicked }) => {
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const variants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.8,
      },
    },
    exit: {
      opacity: 0,
      scale: [1, 0],
    },
  };

  const refreshPage = () => {
    navigate(0)
  };
  const notLoggedIn = () => {
    return (
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="mozart-dialog-container"
      >
        <p className="header-mozart-dialog">
          Mozart odporúča: Prihlás sa aby si z tejto stránky vytažil čo najviac
        </p>
        <div className="mozart-dialog-content">
          <button onClick={() => setLogin(true)} className="login">
            Prihlásenie
          </button>
          <button onClick={() => setRegister(true)} className="registration">
            Registrácia
          </button>
        </div>
      </motion.div>
    );
  };
  const loggedIn = () => {
    return (
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        className={
          cookies.jwt === "undefined"
            ? "mozart-dialog-container"
            : "mozart-dialog-container-logged-in"
        }
      >
        <p className="header-mozart-dialog">
          Ahoj {cookies.username}. Mozart je rád že si sa prihlásil. Ale ak
          chceš odísť tak sa môžeš odhlásiť tu.
        </p>
        <div className="mozart-dialog-content">
          <button
            onClick={() =>
              refreshPage() ||
              setClicked(false) ||
              setLogin(false) ||
              removeCookie("jwt", { path: "/" })
            }
            className="login log-out-button"
          >
            Odhlásiť sa
          </button>
        </div>
      </motion.div>
    );
  };
  return (
    <>
      {cookies?.jwt === "undefined" ||
      cookies?.jwt === null ||
      cookies.jwt === undefined
        ? notLoggedIn()
        : loggedIn()}{" "}
      <AnimatePresence>
        {register && (
          <Register
            setClicked={setClicked}
            setRegister={setRegister}
            setLogin={setLogin}
          />
        )}
        {login && (
          <Login
            setClicked={setClicked}
            setRegister={setRegister}
            setLogin={setLogin}
          />
        )}
      </AnimatePresence>
    </>
  );
};
export default MozartDialog;
