import { useState, memo } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useCookies } from "react-cookie";
import placeholder from "../../../assets/placeholder_sm.webp";
const SheetMusicPreview = (props) => {
  const [hover, setHover] = useState();
  const [cookies, setCookie] = useCookies();
  // framer motion variants
  const variants = {
    hidden: { opacity: 0 },
    hover: {
      y: -10,
      boxShadow: "0 5px 10px #777",
    },
    noHover: {
      boxShadow: "0 0 0 transparent",
    },
  };

  // shows appropriate difficulty tag
  const difficulty = (diff) => {
    switch (diff) {
      case "Zaciatocnik":
        return <span className="easy-sheet-music">Začiatočník</span>;
      case "Pokrocily":
        return <span className="medium-sheet-music">Pokročilý</span>;
      case "Expert":
        return <span className="hard-sheet-music">Expert</span>;
      default:
        return null;
    }
  };
  const AuthSheetMusic = () => {
    if (cookies.jwt !== (undefined || null || "undefined")) {
      return (
        <Link
          to={`/sheet-music/${props.data.attributes.Name.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(" ", "-")}`}
        >
          <motion.div
            className="composer-sheet-music-thumbnail-container"
            initial="enter"
            exit="exit"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            variants={variants}
            animate={hover ? "hover" : ""}
          >
            <motion.div
              variants={variants}
              animate={hover ? "hidden" : ""}
              className="sheet-music-difficulty-thumbnail"
            >
              {difficulty(props.data.attributes.difficulty)}
            </motion.div>
            <Document
              loading={<div className="loader-pdf">Načítavanie...</div>}
              size="A4"
              className="composer-sheet-music-thumbnail"
              file={`${process.env.REACT_APP_API}${props.data.attributes.File.data.attributes.url}#page=1`}
              dpi={20}
            >
              <Page pageNumber={1} size="A4" />
            </Document>
            <motion.div
              variants={variants}
              animate={hover ? "hidden" : ""}
              className="sheet-music-thumbnail-info"
            >
              <p className="sheet-music-name">{props.data.attributes.Name}</p>
              <p>{props.data.attributes.skladatel.data.attributes.Name}</p>
            </motion.div>
          </motion.div>
        </Link>
      );
    }
   else if (cookies.jwt === (undefined || null || "undefined")) {
      return (
        <motion.div
          className="composer-sheet-music-thumbnail-container"
          initial="enter"
          exit="exit"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          variants={variants}
          animate={hover ? "hover" : ""}
        >
          <div className="composer-sheet-placeholder-cover">
            <p className="sheet-music-warning">Prihlás sa aby si videl noty.</p>
          </div>
          <img
            className="composer-sheet-music-thumbnail sheet-music-placeholder"
            src={placeholder}
            alt=""
          />
          <motion.div
            variants={variants}
            className="sheet-music-thumbnail-info"
            animate={hover ? "hidden" : ""}
          >
            <p className="sheet-music-name">Prihlás sa</p>
            <p>Ludwig van Beethoven</p>
          </motion.div>
        </motion.div>
      );
    }
  };
  return AuthSheetMusic();
};
export default memo(SheetMusicPreview);
