import "./Photoalbum.css";
import { useQuery, gql } from "@apollo/client";
import Photo from "./Photo";
import { Helmet } from "react-helmet-async";
import Video from "./Video";
const Photoalbum = () => {
  const GET_LOCATIONS = gql`
    query Photoalbum {
      photos {
        data {
          attributes {
            Name
            Fotky {
              data {
                attributes {
                  url
                }
              }
            }
            Videa {
              data {
                attributes {
                  url
				  mime
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;
  return (
    <div className="big-photo-album-container">
      <h1 className="colorful odbor-header">Fotoalbum</h1>
      <Helmet>
        <title>Fotoalbum | Súkromná základná umelecká škola Tvrdošín</title>
      </Helmet>
      <div>
        {data.photos.data.map((item, index) => {
          return (
            <div className="photo-specific-album-container" key={index}>
              <h2>{item.attributes.Name}:</h2>
              <div className="photo-container-album">
                {item.attributes.Fotky.data.map((item, indexo) => {
                  return (
                    <Photo
                      key={indexo}
                      url={item.attributes.url}
                    />
                  );
                })}
                {item.attributes.Videa.data.map((item, indexo) => {
                  return (
                    <Video
                      key={indexo}
                      url={item.attributes.url}
					  mime={item.attributes.mime}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Photoalbum;
