import "../Photo/Photo.css";
import Fancybox from "../../fancybox.js";
const Video = (props) => {
  return props.data.map((video, index) => {
    return (
      <div key={index} className="blog-image-container">
        <div className="blog-image-container-smaller">
          <div className="video-badge-blog">
            <p>Video</p>
          </div>
          <Fancybox>
            <video
              data-fancybox="video-gallery"
              className="blog-image"
              src={`${process.env.REACT_APP_API}${video.attributes.url}`}
              href={`${process.env.REACT_APP_API}${video.attributes.url}`}
            />
          </Fancybox>
        </div>
      </div>
    );
  });
};

export default Video;
