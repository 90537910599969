import logo from "../../assets/logo.svg";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import React, { useState, memo } from "react";
import Hamburger from "../Button/Hamburger/Hamburger";
import Lupa from "../../assets/lupa.svg";
import Search from "../Search/Search";
import MobileMenu from "./MobileMenu/MobileMenu";
import { useQuery, gql } from "@apollo/client";

const Header = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [, setScroll] = useState(0);
  const [search, setSearch] = useState(false);
  const [hovering, setHovering] = useState("");
  const [, setMenuHover] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const GET_LOCATIONS = gql`
    {
      headers {
        data {
          id
          attributes {
            title
            subheaders {
              data {
                id
                attributes {
                  header {
                    data {
                      attributes {
                        title
                      }
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Chyba</p>;

  const header = () => {
    if (window.innerWidth > 1020) {
      return (
        <ul className="headerItem_Container">
          <li
            className="headerItem_List"
            onMouseEnter={() => setMenuHover(true)}
            onMouseLeave={() => setMenuHover(false)}
          >
            <NavLink className="headerItem link colorful" to="/aktuality">
              Aktuality
            </NavLink>
          </li>
          {data.headers.data.map((item) => {
            return <DesktopMenu key={item.id} data={item} />;
          })}
          <li
            className="headerItem_List"
            onMouseEnter={() => setMenuHover(true)}
            onMouseLeave={() => setMenuHover(false)}
          >
            <NavLink className="headerItem link" to="/kontakt">
              Kontakt
            </NavLink>
          </li>
        </ul>
      );
    } else {
      return (
        <MobileMenu
          ham={toggleHamburger}
          data={data.headers}
          active={hamburgerOpen}
          activeFunc={setHamburgerOpen}
        />
      );
    }
  };

  window.addEventListener("scroll", handleScroll);

  function handleScroll() {
    setScroll(window.scrollY);
  }

  return (
    <header className="header-container_main">
      <div className="header">
        <div className="hamburger" onClick={toggleHamburger}>
          <Hamburger open={hamburgerOpen} />
        </div>
        <Link to="/">
          <img
            className="logo"
            src={logo}
            alt="logo"
            width="289.49"
            height="90"
          />
        </Link>

        <nav className="header-items-lupa">
          {header()}

          {/*lupa*/}
          <svg
            onMouseEnter={() => setHovering("lupa-hover")}
            onMouseLeave={() => setHovering("")}
            onClick={() => setSearch(true)}
            src={Lupa}
            alt="Hľadať"
            className="lupa"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256.001 256.001"
          >
            <rect width="256" height="256" fill="none" />
            <circle
              className={`lupa-strokes ${hovering}`}
              cx="115.997"
              cy="116"
              r="84"
              fill="none"
              stroke="#393939"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="30"
            />
            <line
              className={`lupa-strokes ${hovering}`}
              x1="175.391"
              x2="223.991"
              y1="175.4"
              y2="224.001"
              fill="none"
              stroke="#393939"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="30"
            />
          </svg>

          <Search opened={search} openedFunc={setSearch} />
        </nav>

        <style jsx="true">{`
      @media screen and (max-width: 1020px) {
      
        .desktop-menu-child{
          width: 100%;
          left: 0px;
          transform: rotate(-90deg);
        }
        .logo{
          margin:0 !important;
        }
        .hamburger{
          display: flex !important;
          justify-content: center;
          align-items: center;
          display: block;
          left: 10px;
          float: right;
        }
        .headerItem{
          display: block;
        }
      `}</style>
      </div>
    </header>
  );
};
export default memo(Header);
