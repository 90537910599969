import "./ComposerCard.css";
import { motion } from "framer-motion";
import { useState, memo } from "react";
import { Link } from "react-router-dom";

const NotyMainPage = ({ data }) => {
  const [hover, setHover] = useState(false);

  const variants = {
    imageHover: {
      filter: 0,
      y: -20,
      boxShadow: "0 5px 10px #777",
    },
    captionHover: {
      y: -20,
    },
  };

  return (
    <Link
      variants={variants}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="composer-card-container"
      to={`/skladatel/${data.attributes.Slug}`}
    >
      <motion.div className="composer-card">
        <motion.img
          transition={{
            type: "spring",
            stiffness: 200,
          }}
          variants={variants}
          animate={hover ? "imageHover" : ""}
          src={`${process.env.REACT_APP_API}${data.attributes.Photo.data.attributes.url}?quality=50&format=webp`}
          alt={data.attributes.Name}
        />
        <motion.div
          transition={{
            type: "spring",
            stiffness: 200,
          }}
          variants={variants}
          animate={hover ? "captionHover" : ""}
          className="composer-card-name"
        >
          <motion.p>{data.attributes.Name}</motion.p>
        </motion.div>
      </motion.div>
    </Link>
  );
};

export default memo(NotyMainPage);
