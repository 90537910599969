import "./News.css";
import Type from "../Type/Type";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"

const News = (props) => { 

const variants ={
  clicked:{
    visible: "hidden",
    opacity: 0,
  },
}
  const dateOld = Date.parse(props.data.attributes.createdAt);
  return (
    <motion.div className="card-parent" variants={variants} key={props.data.id}>
      <Link
        to={`/blog/${props.data.attributes.slug}`}
      >
        <motion.div
          layoutId={props.data.id}
          className="news-card"
          whileHover={{
            scale: 1.02,
            translateY: -10,
            boxShadow: "0 5px 5px rgba(0, 0, 0, 0.1)",
          }}
          whileTap={{
            scale: 0.95,
          }}
        >
          <motion.div className="card-news-inner" variants={variants}>
            <img
              className="card-image"
              src={
                process.env.REACT_APP_API +
                props.data.attributes.Cover.data.attributes.url +
                "?quality=50&format=webp&resize=400"
              }
              alt="cover"
              loading="lazy"
            />
            <Type type={props.data.attributes.type} />
            <p className={`card-date`}>
              Pridané: {new Date(dateOld).toLocaleDateString("sk-SK")}
            </p>
            <h3 className={`card-title`}>{props.data.attributes.title}</h3>
          </motion.div>
        </motion.div>
      </Link>
    </motion.div>
  );
};
export default News;
