import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./Login.css"
import { useCookies } from "react-cookie";

const LoginRedirect = (props) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [, setCookie,] = useCookies();
  const [text, setText] = useState("Loading...");
  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(
      `${process.env.REACT_APP_API}/api/auth/${params.provider}/callback${location.search}`
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        setCookie("jwt", res.jwt, { path: "/" });
        setCookie("username", res.user.username, { path: "/" });
        setText(
          "Bol si úspešne prihlásený. Za pár sekúnd ťa presmerujeme naspäť."
        );
        setTimeout(() => navigate("/"), 3000);
      })
      .catch((err) => {
        console.log(err);
        setText("Vyskytla sa chyba. Skúste to znova. Inak kontaktujte administrátora na admin@szustvrdosin.sk");
      });
  }, [navigate, location.search, params.provider]);
  return <p className="provider-redirect">{text}</p>;
};

export default LoginRedirect;
