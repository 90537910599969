const MusicComponent = ({ data }) => {
  const player = (item, index) => {

    let result;
    switch (item.__typename) {
      case "ComponentVideoAudio":
        result = (
          <audio
            key={index}
            controls
            src={`${process.env.REACT_APP_API}${item.audio.data.attributes.url}`}
          />
        );
        break;
      case "ComponentVideoVideo":
        result = (
          <video
            key={index}
            controls
            src={`${process.env.REACT_APP_API}${item.video.data.attributes.url}`}
          />
        );
        break;
        case "ComponentVideoEmbed":
          result = (
            <iframe
            className="youtube-embed-sm"
              width="560"
              height="315"
              src={`https://www.youtube-nocookie.com/embed/${item.embed}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          );
          break;
      default:
        result = null;
    }
    return result;
  };

  return (
    <div className="embed-container">
        {data.map((item, index) => {
          return player(item, index);
        })}
    </div>
  );
};
export default MusicComponent;
