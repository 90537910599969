import { useQuery, gql } from "@apollo/client";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";
import "./NotyPage.css";
import MusicComponent from "./MusicComponent";
import download from "../../../assets/download.svg";
import share from "../../../assets/share.svg";
import printer from "../../../assets/printer.svg";
import { AnimatePresence } from "framer-motion";
import ShareMenu from "./ShareMenu/ShareMenu";
import { useParams } from "react-router-dom";
import NotAuth from "../../Login/NotAuth/NotAuth";
import LikedSheetMusic from "./LikedSheetMusic";

const NotyPage = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [shareMenu, setShareMenu] = useState(false);

  let { slug } = useParams();
  const GET_LOCATIONS = gql`
{
  notes(filters: { slug: { eq: "${slug}" } }) {
    data {
      id
      attributes {
        Name
        difficulty
        createdAt
        skladatel {
          data {
            attributes {
              Name
            }
          }
        }
        File {
          data {
            attributes {
              name
              url
            }
          }
        }
        component {
          __typename
          ... on ComponentVideoAudio {
            audio {
              data {
                attributes {
                  url
                }
              }
            }
          }
          
          __typename
          ... on ComponentVideoEmbed {
            embed
          }
          __typename
          ... on ComponentVideoVideo {
            video {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}

`;
  const difficulty = (diff) => {
    switch (diff) {
      case "Zaciatocnik":
        return <span className="easy-sheet-music">Začiatočník</span>;
      case "Pokrocily":
        return <span className="medium-sheet-music">Pokročilý</span>;
      case "Expert":
        return <span className="hard-sheet-music">Expert</span>;
      default:
        return null;
    }
  };

  const noauth = () => {
    return (
      <NotAuth />
    );
  };

  const { loading, error, data } = useQuery(GET_LOCATIONS);
  if (loading) return null;
  if (error) return noauth();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);


  return (
    <div className="sheet-music-container-main">
      {shareMenu && (
        <AnimatePresence>
          <ShareMenu
            shareMenu={shareMenu}
            setShareMenu={setShareMenu}
            data={data.notes.data[0].attributes}
          />
        </AnimatePresence>
      )}
      <div className="sheet-music-left-side">
        <Document
          loading={<div className="loader-pdf">Načítavanie...</div>}
          size="A4"
          className="sheet-music-page"
          file={`${process.env.REACT_APP_API}${data.notes.data[0].attributes.File.data.attributes.url}`}
          dpi={20}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} size="A4" />
        </Document>
        <div className="controls-pdf">
          <div>
            <button className="pdf-button" onClick={goToPrevPage}>
              Predošlá
            </button>
            <button className="pdf-button" onClick={goToNextPage}>
              Nasledujúca
            </button>
          </div>
          <p>
            Strana {pageNumber} z {numPages}
          </p>
        </div>
      </div>
      <div className="sheet-music-right-side">
        <div className="sheet-music-topbar">
          <p>{data.notes.data[0].attributes.skladatel.data.attributes.Name}</p>
          {difficulty(data.notes.data[0].attributes.difficulty)}
        </div>
        <h1 className="colorful odbor-header">
          {data.notes.data[0].attributes.Name}
        </h1>
        <div className="sheet-music-info">
          <div className="sheet-music-buttons">
            <div
              className="button-sheet-music-left main-button-sheet-music justify-content"
              onClick={() => {
                fetch(
                  `${process.env.REACT_APP_API}${data.notes.data[0].attributes.File.data.attributes.url}`
                ).then((response) => {
                  response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${data.notes.data[0].attributes.Name}.pdf`;
                    alink.click();
                  });
                });
              }}
            >
              <p>Stiahnuť</p>
              <img src={download} alt="stiahnuť súbor" />
            </div>
            <a
              className="button-sheet-music-left no-margin-right"
              href={`${process.env.REACT_APP_API}${data.notes.data[0].attributes.File.data.attributes.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Tlačiť</p>
              <img src={printer} alt="stiahnuť súbor" />
            </a>
            <div
              className="button-sheet-music-left justify-content no-margin-bottom"
              onClick={() => {
                setShareMenu(!shareMenu);
              }}
            >
              <p>Zdieľať</p>
              <img src={share} alt="stiahnuť súbor" />
            </div>
              <LikedSheetMusic id={data.notes.data[0].id} />
          </div>
        </div>
        <MusicComponent data={data.notes.data[0].attributes.component} />
      </div>
    </div>
  );
};
export default NotyPage;
