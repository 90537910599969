import { motion } from "framer-motion";
import "./AppsButton.css";
import info from "../../assets/info.svg";
import Camera from "../lotties/camera.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import LogoBlack from "../../assets/logo_black.svg";

const AppIcons = (props) => {
  const appsVariants = {
    open: {
      display: "block",
      height: "244px",
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    closed: {
      height: "0px",
      transition: {
        delay: 1,
        staggerChildren: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
    none: {
      display: "none",
    },
  };
  const icon = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
    closed: {
      y: 5,
      opacity: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const itemApps = {
    closed: {
      y: 20,
      opacity: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const notes ={
    hover:{
      rotate: [null, -20, 20, -20, 20, -20, 0],
    }
  }

  const image = {
    closed: {
      y: 10,
      opacity: 0,
      transition: {
        delay: 0.8,
      },
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
      },
    },
    info: {
      rotate: "360deg",
      transition: {
        duration: 0.5,
      },
    },
  };
  const camera = {
    closed: {
      y: 10,
      opacity: 0,
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1,
      },
    },
  };

  return (
    <motion.div
      initial="none"
      className="menu-icons-container"
      variants={appsVariants}
      animate={props.open ? "open" : "closed"}
    >
      <motion.a
        href="/fotoalbum"
        key="0"
        variants={icon}
        whileHover={{
          translateY: -5,
          scale: 1.05,
          boxShadow: "0px 0px 10px rgb(0, 0, 0, 0.4)",
        }}
        iconname="Fotoalbum"
        className="menu-app-container"
      >
        {/*Photoalbum */}
        <motion.div variants={camera}>
          <Player direction={-1} src={Camera} keepLastFrame={true} hover={true}>
            <Controls visible={false} />
          </Player>
        </motion.div>
        {/*Photoalbum */}
      </motion.a>
      <motion.a
        href="/noty"
        key="1"
        variants={icon}
        whileHover={{
          translateY: -5,
          scale: 1.05,
          boxShadow: "0px 0px 10px rgb(0, 0, 0, 0.4)",
        }}
        iconname="Noty"
        className="menu-app-container"
      >
        {/*lottie*/}
        <motion.div variants={notes} whileHover="hover" className="notes">
          <img src={LogoBlack} alt="tmavé-logo" />
        </motion.div>
        {/*lottie*/}
      </motion.a>
      {/*apps*/}
      <motion.div
        onClick={() =>
          props.setClick(true) ||
          setTimeout(() => (window.location.pathname = "/aplikacie"), 1000)
        }
        iconname="Aplikácie"
        key="2"
        variants={icon}
        whileHover={{
          translateY: -5,
          scale: 1.05,
          boxShadow: "0px 0px 10px rgb(0, 0, 0, 0.4)",
        }}
        className="menu-app-container menu-app-container-icons"
      >
        <motion.div
          whileHover={{ rotate: "360deg", scale: 1.05 }}
          key={3}
          className="item-apps-menu-icon"
          variants={itemApps}
        />
        {[0, 1, 2].map((index) => (
          <motion.div
            key={index}
            className="item-apps-menu-icon"
            variants={itemApps}
          />
        ))}
      </motion.div>
      {/*apps*/}
      {/*info*/}
      <motion.div
        onClick={() => {
          props.setCookieState(false);
          props.setCookie("closed", false, { path: "/" });
        }}
        iconname="Info"
        key="3"
        variants={icon}
        whileHover={{
          translateY: -5,
          scale: 1.05,
          boxShadow: "0px 0px 10px rgb(0, 0, 0, 0.4)",
        }}
        className="menu-app-container"
      >
        <motion.img
          animate={props.open ? "open" : "closed"}
          variants={image}
          whileHover="info"
          src={info}
          alt="info"
        />
      </motion.div>
      {/*info*/}
    </motion.div>
  );
};
export default AppIcons;
