import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import "./Error.css";
const Error = (error) => {
  const [id, setId] = useState(error.message.message);
  const [drag, setDrag] = useState(true);
  const errorMessage = (error) => {
    let errorMessageSwitch;
    switch (error.message.message) {
      case "email must be a valid email":
        errorMessageSwitch = "E-mail je neplatný";
        break;
      case "username must be at least 3 characters":
        errorMessageSwitch = "Uživatelské meno musí mať aspoň 3 znaky";
        break;
      case "Email or Username are already taken":
        errorMessageSwitch =
          "E-mail alebo použivateľské meno je už zaregistrované";
        break;
      case "Invalid identifier or password":
        errorMessageSwitch =
          "Neplatné heslo alebo E-mail";
        break;
      default:
        errorMessageSwitch = error.message.message;
        break;
    }
    return errorMessageSwitch;
  };
  const variants = {
    initial: {
      opacity: 0,
      x: -40,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      {drag && (
        <motion.div
          drag="x"
          onDrag={(event, info) => {
            if (info.point.x > 850) {
              setDrag(false);
            }
          }}
          dragConstraints={{ left: 0 }}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
          dragElastic={0.1}
          transition={{ delay: 0.2 }}
          key={id}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="error"
        >
          <p className="error-message-header">Chyba</p>
          <div className="error-message">{errorMessage(error)}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default Error;
