import "./Photoalbum.css";
import Fancybox from "../../fancybox.js";
import { motion } from "framer-motion";
const Video = ({ url, mime }) => {
  const image = {
    hover: {
      scale: 1.05,
      y: -10,
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
    },
  };
  return (
    <motion.div
      variants={image}
      whileHover="hover"
      className="photoalbum-photo"
    >
      <div className="video-badge">
        <p>Video</p>
      </div>
      <Fancybox>
        <video
          data-fancybox="video-gallery"
          src={`${process.env.REACT_APP_API}${url}`}
          href={`${process.env.REACT_APP_API}${url}`}
        />
      </Fancybox>
    </motion.div>
  );
};
export default Video;
