import "./AppsButton.css";
import { motion } from "framer-motion";
import { useState } from "react";
import logo from "../../assets/logo.svg";
import AppIcons from "./AppIcons";
import Warning from "../Warning/Warning";
import { useCookies } from "react-cookie";

const Apps = () => {
  const [hover, setHover] = useState(false);
  const [click, setClick] = useState(false);
  const [cookies, setCookie] = useCookies(["closed"]);
  const [cookieState, setCookieState] = useState(cookies.closed);

  const variants = {
    hover: {
      width: "30px",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    showLoadingScreen: {
      opacity: 1,
      transform: "translateX(0%)",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hideLoadingScreen: {
      opacity: 0,
      transform: "translateX(-100%)",
      transition: { type: "spring", stiffness: 150, damping: 10 },
    },
    bigLogo: {
      width: ["20rem", "30rem"],
      transition: {
        delay: 0.5,
        type: "spring",
      },
    },
    // animate menu items when clicking on the mmenu
    appsBigMenu: {
      height: "301px",
    },
    appsSmallMenu: {
      height: "56px",
      transition: {
        delay: 1,
      },
    },
  };

  return (
    <motion.div
      className="apps-dots"
      variants={variants}
      animate={hover ? "appsBigMenu" : "appsSmallMenu"}
    >
      <Warning
        cookieState={cookieState}
        setCookie={setCookie}
        setCookieState={setCookieState}
      />
      <motion.div
        initial="hideLoadingScreen"
        className="loading-screen"
        variants={variants}
        animate={click ? "showLoadingScreen" : ""}
      >
        <motion.img
          src={logo}
          alt="logo školy"
          variants={variants}
          animate={click ? "bigLogo" : ""}
        />
      </motion.div>
      <AppIcons
        cookieState={cookieState}
        setCookie={setCookie}
        setCookieState={setCookieState}
        
        open={hover}
        setClick={setClick}
        click={click}
      />
      <div
        onClick={() => {
          setHover(!hover);
        }}
        className="small-dots-container"
      >
        <div className="small-app-dots-container-left">
          <motion.div className="apps-dot-left" />
          <motion.div className="apps-dot-middle" />
          <motion.div className="apps-dot-right" />
        </div>
        <div className="small-app-dots-container-middle">
          <motion.div
            variants={variants}
            animate={hover ? "hover" : ""}
            className="apps-dot-left"
          />
          <motion.div
            variants={variants}
            animate={hover ? "hover" : ""}
            className="apps-dot-middle"
          />
          <motion.div
            variants={variants}
            animate={hover ? "hover" : ""}
            className="apps-dot-right"
          />
        </div>
        <div className="small-app-dots-container-right">
          <motion.div className="apps-dot-left" />
          <motion.div className="apps-dot-middle" />
          <motion.div className="apps-dot-right" />
        </div>
      </div>
    </motion.div>
  );
};
export default Apps;
