import "./Photo.css";
import Fancybox from "../../fancybox.js";
const Photo = (props) => {
  return props.data.map((photo, index) => {
    return (
      <div key={index} className="blog-image-container">
        <div className="blog-image-container-smaller">
          <Fancybox key={index}>
            <img
              data-fancybox="gallery"
              className="blog-image"
              src={`${process.env.REACT_APP_API}${photo.attributes.url}`}
              alt={photo.attributes.title}
            />
          </Fancybox>
        </div>
      </div>
    );
  });
};

export default Photo;
