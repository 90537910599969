import "./Mascot.css";
import mozart from "../../assets/mozart.png";
import mozartHand from "../../assets/mozart_hand.png";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import MozartDialog from "./MozartDialog";
const Mascot = () => {
  const [clicked, setClicked] = useState(false);
  const mascot = {
    initial: {
      scale: 0.8,
      rotateZ: 90,
      x: 100,
      rotateY: 180,
    },
    hover: {
      x: 80,
    },
    clicked: {
      scale: [0.8, 1],
      x: [80, 200, 49],
      rotateZ: [90, 90, 45],
    },
    notClicked: {
      scale: [3, 0.8],
      rotateZ: [45, 0],
      x: [49, 230],
    },
    hand: {
      rotate: [0, -40],
      transition: {
        delay: 0.3,
      },
    },
  };

  return (
    <motion.div className="mascot-container">
      <motion.div
        onClick={() => setClicked(!clicked)}
        initial="initial"
        whileHover={clicked ? "" : "hover"}
        variants={mascot}
        animate={clicked ? "clicked" : ""}
        className="mascot"
      >
        <img src={mozart} className="mozart" alt="maskot mozart" />
        <motion.img
          className="mozart-hand"
          variants={mascot}
          animate={clicked ? "hand" : ""}
          src={mozartHand}
          alt="#"
        />
      </motion.div>
      <AnimatePresence>
        {clicked && <MozartDialog setClicked={setClicked} clicked={clicked} />}
      </AnimatePresence>
    </motion.div>
  );
};

export default Mascot;
