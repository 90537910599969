import "./Search.css";
import X from "../../assets/x.svg";
import algoliasearch from "algoliasearch/lite";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Index,
  Highlight,
} from "react-instantsearch-hooks-web";


const algoliaClient = algoliasearch(
  "RMGYMQZY58",
  "d6f96ddcd634b8f921183f8eb5f5319d"
);
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const Search = (props) => {
  const BlogHit = ({ hit }) => (
    <div className="search-result-div">
      <Link
        to={`/blog/${hit.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "-")}`}
        onClick={() => {
          props.openedFunc(false);
        }}
      >
        <div className="search-result-div-smaller">
          <em>
            <b>{hit.type} /</b>
          </em>
          &nbsp;
          <Highlight hit={hit} attribute="title" />
        </div>
        <img
          src={`${process.env.REACT_APP_API}${hit.Cover.formats.thumbnail.url}`}
          alt="malý obrázok"
          className="img-thumbnail-search"
        />
      </Link>
    </div>
  );
  const HeaderHit = ({ hit }) => (
    <div className="search-result-div">
      <Link
        to={`/${hit.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "-")}`}
        onClick={() => {
          props.openedFunc(false);
        }}
      >
        <div>
          <Highlight hit={hit} attribute="title" />
        </div>
      </Link>
    </div>
  );
  const SubheaderHit = ({ hit }) => (
    <div className="search-result-div">
      <Link
        to={`/${hit.header.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "-")}/${hit.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "-")}`}
        onClick={() => {
          props.openedFunc(false);
        }}
      >
        <div>
          <em>
            <b>{hit.header.title} /</b>
          </em>
          &nbsp;
          <Highlight hit={hit} attribute="title" />
        </div>
      </Link>
    </div>
  );

    const TeacherHit = ({ hit }) => (
      <div className="search-result-div">
        <Link
          to={`/${hit.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(" ", "-")}`}
          onClick={() => {
            props.openedFunc(false);
          }}
        >
          <div>
              <b>
                <Highlight style={{fontWeight: 900}} hit={hit} attribute="name" />
              </b>
          &nbsp;
            <b style={{ fontWeight: "bold", color: "#666" }}>{hit.zamestnanie}</b>
          </div>
        </Link>
      </div>
    );
  const variants = {
    open: () => ({
      height: "76%",
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        duration: 0.1,
        damping: 40,
      },
    }),
    closed: () => ({
      opacity: 0,
      height: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 40,
        duration: 0.1,
      },
    }),
    visibleBig: () => ({
      display: "flex",
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 0.0001,
        type: "spring",
      }
    }),
    hiddenEntirely: () => ({
      display: "none",
      opacity: 0,
      translateY: "-100%",
      transition: {
        delay: 0.2,
        when: "afterChildren",
      },
    })
  };

  return (
    <motion.div
      className="search_container"
      variants={variants}
      initial="hiddenEntirely"
      animate={props.opened ? "visibleBig" : "hiddenEntirely"}
    >
      <motion.div
        variants={variants}
        initial="closed"
        animate={props.opened ? "open" : "closed"}
        className="search-dialog"
      >
        <div
          className="search-close_div"
          onClick={() => {
            props.openedFunc(false);
          }}
        >
          <img className="warning-close" src={X} alt="zatvoriť" />
        </div>
        <h4>Vyhľadávanie</h4>
        <div className="search-results">
          <InstantSearch
            indexName="development_api::blog.blog"
            searchClient={searchClient}
          >
            <SearchBox placeholder="Hľadať" role="search" />
            <div className="results">
              <Index indexName="development_api::blog.blog">
                <Hits hitComponent={BlogHit} />
              </Index>
              <Index indexName="development_api::teacher.teacher">
                <Hits hitComponent={TeacherHit} />
              </Index>
              <Index indexName="development_api::header.header">
                <Hits hitComponent={HeaderHit} />
              </Index>
              <Index indexName="development_api::subheader.subheader">
                <Hits hitComponent={SubheaderHit} />
              </Index>
            </div>
          </InstantSearch>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default memo(Search);
