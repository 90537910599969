import ReactDOM from "react-dom/client";
import React from "react";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API}/graphql`,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem("jwt") === "undefined"  ? "": localStorage.getItem("jwt")}`,
  },
});
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <link rel="stylesheet" href="https://use.typekit.net/szk7zbm.css" />
        <CookiesProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </CookiesProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
