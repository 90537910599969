import "./DesktopMenu.css";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
const DesktopMenu = (props) => {
  const [menuHover, setMenuHover] = useState();

  const floatingMenu = () => {
    if (props.data.attributes.subheaders.data.length !== 0) {
      return (
        <div
          className={`desktop-menu ${menuHover ? "desktop-menu-shown": ""}`}
        >
          {props.data.attributes.subheaders.data.map((item) => {
            return (
              <div className="desktop-menu-child" key={item.id}>
                <NavLink
                  key={item.id}
                  to={`/${item.attributes.header.data.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}/${item.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}`}
                  className={`link desktop-menu-item`}
                >
                  {item.attributes.title}
                </NavLink>
              </div>
            );
          })}
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <li
      className="headerItem_List"
      key={props.data.id}
      onMouseEnter={() => setMenuHover(true)}
      onMouseLeave={() => setMenuHover(false)}
    >
      <NavLink
        className={`headerItem link ${menuHover ? "active" : ""}`}
        to={`/${props.data.attributes.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "")}`}
      >
        {props.data.attributes.title}
      </NavLink>
      {floatingMenu()}
    </li>
  );
};

export default DesktopMenu;
