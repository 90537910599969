import TypeBadge from "../TypeBadge";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import x from "../../../assets/x.svg";
import Markdown from "markdown-to-jsx";

const AppCard = (props) => {
  const [selectedId, setSelectedId] = useState(false);
  const [hover, setHover] = useState();
  const [selectedInstruction, setSelectedInstruction] = useState(0);

  const variants = {
    hover: {
      scale: 1.1,
      y: -10,
      boxShadow: "0 10px 10px rgba(0, 0, 0, 0.1)",
    },
    active: {
      backgroundColor: "#f15a24",
      color: "#fff",
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 80,
      },
    },

    inactive: {
      backgroundColor: "#edf0f2",
      color: "#727482b3",
    },
    numberHover: {
      backgroundColor: "#f15a24",
    },
    initial: {
      opacity: 0,
    },
    enter: {
      zIndex: 1,
      opacity: 1,
      x: 0,
    },
    exit: {
      zIndex: 0,
      opacity: 0,
    },
  };
  const image = (image) => {
    if (image === undefined) {
      return;
    }
    if (image.image.data === null) {
      return null;
    }
    if (image.image.data != null) {
      return (
        <img
          data-fancybox="image-single"
          src={`${process.env.REACT_APP_API}${props.data.attributes.instruction[selectedInstruction].image.data.attributes.url}`}
          alt={props.data.attributes.name}
        />
      );
    }
  };
  const description = (desc) => {
    if (desc === undefined) {
      return;
    }
    if (desc.description === null) {
      return null;
    }
    if (desc.description != null) {
      return <Markdown>{desc.description}</Markdown>;
    }
  };
  return (
    <>
      <motion.div
        whileHover="hover"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="single-app-container"
        variants={variants}
        key={props.index}
        layoutId={`app-card-${props.index}`}
      >
        <motion.div className="card-no-image-container">
          <motion.div className="top-card-info-apps">
            <TypeBadge data={props.data} />
            <motion.div
              className="app-more-info"
              onClick={() => setSelectedId(!selectedId)}
            >
              <motion.img
                src={x}
                alt="viac info"
                whileHover={{ rotate: "90deg" }}
                initial={{ rotate: "45deg" }}
              />
            </motion.div>
          </motion.div>
          <motion.h3 className="h3-apps">
            {props.data.attributes.name}
          </motion.h3>
        </motion.div>
        <motion.img
          src={`${process.env.REACT_APP_API}${props.data.attributes.icon.data.attributes.url}?quality=100&format=webp`}
          alt={props.data.attributes.name}
        />
      </motion.div>
      <AnimatePresence className="app-presence-container">
        {selectedId && (
          <motion.div className="container-apps-big">
            <motion.div
              onClick={() => setSelectedId(!selectedId)}
              className="big-nothing"
            />
            <motion.div
              transition={{
                type: "spring",
                stiffness: 100,
              }}
              className="app-more-info-big"
              layoutId={`app-card-${props.index}`}
            >
              <motion.div
                className="app-more-info"
                onClick={() => setSelectedId(false)}
              >
                <motion.img
                  src={x}
                  alt="viac info"
                  whileHover={{ rotate: "90deg" }}
                  initial={{ rotate: "45deg" }}
                />
              </motion.div>
              <motion.h3
                className="h3-apps"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {props.data.attributes.name}
              </motion.h3>
              <motion.div className="apps-info-container">
                <div className="apps-manual-numbers-container">
                  <motion.ul>
                    {props.data.attributes.instruction.map((item, index) => {
                      return (
                        <motion.li
                          variants={variants}
                          key={index}
                          onClick={() => setSelectedInstruction(index)}
                          animate={
                            index === selectedInstruction
                              ? "active"
                              : "inactive"
                          }
                        >
                          <span className="manual-numbers">{index + 1}</span>
                        </motion.li>
                      );
                    })}
                  </motion.ul>
                  <motion.a
                    target={"_blank"}
                    opener={"noopener noreferrer"}
                    href={props.data.attributes.link}
                    className="app-open-link"
                  >
                    Otvoriť <span>{props.data.attributes.name}</span>
                  </motion.a>
                </div>
                <div className="apps-description-container">
                  <AnimatePresence>
                    <motion.div
                      animate="enter"
                      initial="initial"
                      exit="exit"
                      variants={variants}
                      key={selectedInstruction}
                      className="apps-info-text-container"
                    >
                      {image(
                        props.data.attributes.instruction[selectedInstruction]
                      )}
                      {description(
                        props.data.attributes.instruction[selectedInstruction]
                      )}
                    </motion.div>
                  </AnimatePresence>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default AppCard;
