import "./Composer.css";
import { useQuery, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import SheetMusicPreview from "./SheetMusicPreview";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router-dom";
import NotAuth from "../../Login/NotAuth/NotAuth";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Composer = () => {
  //graphql query
  let { slug } = useParams();
    const [cookies, setCookie] = useCookies();
  const GET_LOCATIONS = gql`{
    skladatels(filters: { Slug: { eq: "${slug}" } }) {
    data {
      attributes {
        Name
        Photo {
          data {
            attributes {
              url
            }
          }
        }
        noties {
          data {
            attributes {
              skladatel {
                data {
                  attributes {
                    Name
                  }
                }
              }
              Name
              difficulty
              File {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
  `;

  const Variants = {
    enter: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: -20,
    },
    initial: {
      opacity: 0,
      y: -20,
    },
  };

  const { loading, error, data } = useQuery(GET_LOCATIONS);
  const [sortedArray, setSortedArray] = useState([]);

  const noauth = () => {
    return <NotAuth />;
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      return;
    } else {
      setSortedArray(data.skladatels.data[0].attributes.noties.data);
    }
  }, [loading, error, data]);

  if (loading) return null;
  if (error) return noauth();
  
  const handleChange = (event) => {
    setSortedArray(
      sortedArray.filter(
        (item) => item.attributes.difficulty === event.target.name
      )
    );
    if (!event.target.checked) {
      setSortedArray(data.skladatels.data[0].attributes.noties.data);
    }
  };

  const search = (event) => {
    setSortedArray(
      data.skladatels.data[0].attributes.noties.data.filter((item) =>
        item.attributes.Name.toLowerCase().includes(event.target.value)
      )
    );
  };

  return (
    <div>
      <div className="composer-container-top">
        <img
          className="composer-image"
          src={`${process.env.REACT_APP_API}${data.skladatels.data[0].attributes.Photo.data.attributes.url}`}
          alt={data.skladatels.data[0].attributes.Name}
        />
        <div className="composer-info_container">
          <div className="composer-info-top">
            <h2 className="colorful">
              {data.skladatels.data[0].attributes.Name}
            </h2>
          </div>
          <div className="composer-top-filtering">
            <p className="composer-top-filtering-filter-word">Filtre</p>
            <p className="difficulty">Náročnosť</p>
            <div className="difficulty-container">
              <label>
                <input
                  type="checkbox"
                  name="Zaciatocnik"
                  onChange={handleChange}
                />
                Začiatočník
              </label>
              <label>
                <input
                  type="checkbox"
                  name="Pokrocily"
                  onChange={handleChange}
                />
                Pokročilý
              </label>
              <label>
                <input type="checkbox" name="Expert" onChange={handleChange} />
                Expert
              </label>
            </div>
            <div className="file-search">
              <input
                role="search"
                type="text"
                placeholder="Hľadať medzi notami..."
                onChange={(event) => search(event)}
              />
            </div>
          </div>
        </div>
      </div>
      <motion.div className="sheet-music-container">
        <AnimatePresence>
          {sortedArray.map((item, index) => {
            return (
              <motion.div
                className="composer-sheet-music-thumbnail-container-big"
                variants={Variants}
                animate="enter"
                initial="initial"
                exit="exit"
                key={item.attributes.Name}
              >
                <SheetMusicPreview data={item} />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};
export default Composer;
