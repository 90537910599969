import "./HeaderBlog.css";
import Type from "../../News/Type/Type";
import HeaderFile from "./HeaderFile";
import Markdown from "markdown-to-jsx";
import { NavLink } from "react-router-dom";
import Photo from "../../Photo/Photo";
import Video from "../../Video/Video";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";

const HeaderBlog = (props) => {
  const dateOld = Date.parse(props.blogData.attributes.publishedAt);
  const GET_LOCATIONS = gql`
  {
  subheader (id: ${props.blogData.id}) {
    data {
      attributes {
        title
        content
        header {
          data {
            attributes {
              title
            }
          }
        }
        pictures {
          data {
            id
            attributes {
              url
            }
          }
        }
        videos{
          data{
            id
            attributes {
              url
            }
          }
        }
        files {
          __typename
          ... on ComponentFileFile {
            name
            file(pagination:{pageSize: 10000}) {
              data {
                id
                attributes {
                  size
                  url
                  ext
                  name
                }
              }
            }
          }
        }
        publishedAt
      }
    }
  }
}
`;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;

  const files = () => {
    if (data.subheader.data.attributes.files === null) {
      return;
    } else {
      return data.subheader.data.attributes.files.map((item, index) => {
        return (
          <div className="file" key={index}>
            <h2 className="h3-files">{item.Name}</h2>
            <HeaderFile data={item.file} />
          </div>
        );
      });
    }
  };

  const sidebar = () => {
    return (
      <div className="sidebar_container">
        <div className="sidebar">
          <h4 className="sidebar_item more_results colorful">
            Podobné výsledky
          </h4>
          {props.data.attributes.subheaders.data.map((item) => {
            return (
              <div key={item.id} className="sidebar_item">
                <NavLink
                  className=" link"
                  to={`/${item.attributes.header.data.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}/${item.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}`}
                >
                  {item.attributes.title}
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const topbar = () => {
    if (
      window.location.pathname.substring(0, 6) === "/odbor" ||
      window.location.pathname.substring(0, 5) === "/blog"
    ) {
      return (
        <div className="odbor-top">
          {<p>Pridané: {new Date(dateOld).toLocaleDateString("sk-SK")}</p>}
          <Type type={props.data.attributes.type} />
        </div>
      );
    }
  };
  const photos = () => {
    if (data.subheader.data.attributes.pictures.data.length === 0 && data.subheader.data.attributes.videos.data.length === 0) {
      return;
    } else {
      return (
        <div className="photo-container_main">
          <h2 className="h3-files">Fotky:</h2>
          <div className="photo-container">
            <Photo data={data.subheader.data.attributes.pictures.data} />
            <Video data={data.subheader.data.attributes.videos.data} />
          </div>
        </div>
      );
    }
  };
  const content = () => {
    return <Markdown>{data.subheader.data.attributes.content}</Markdown>;
  };
  return (
    <div className="main-odbor_container">
      <Helmet>
        <title>
          {data.subheader.data.attributes.title} | Súkromná základná
          umelecká škola Tvrdošín
        </title>
      </Helmet>
      {sidebar()}
      <div className="odbor-main_content">
        <div className="content_container">
          {topbar()}
          <ul className="breadcrumbs">
            <li>
              <a href="/">{"Úvod >"}</a>
            </li>
            <li>
              <a
                href={`/${data.subheader.data.attributes.header.data.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}`}
              >
                {
                  data.subheader.data.attributes.header.data.attributes
                    .title
                }{" "}
                {" > "}
              </a>
            </li>
            <li>
              <a
                href={`/${data.subheader.data.attributes.header.data.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(
                    " ",
                    "-"
                  )}/${data.subheader.data.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}`}
              >
                {data.subheader.data.attributes.title}
              </a>
            </li>
          </ul>
          <div className="title_content">
            <h1 className="colorful odbor-header">
              {data.subheader.data.attributes.title}
            </h1>
            {content()}
          </div>
        </div>
        <div className="others_container">
          {photos()}
          {files()}
        </div>
      </div>
    </div>
  );
};

export default HeaderBlog;
