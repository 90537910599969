import "./404.css"
import vinyl from "../../assets/vinyl.png"
import Man from "../../assets/404_man.webp"
import { Link } from "react-router-dom";
const fof = () =>{
	return (
    <div className="fof-container">
      <h1>Chyba</h1>
      <p className="fof-num">4 &nbsp; 4!</p>
      <img src={vinyl} alt="platňa" className="vinyl" />
      <h2>Stránka nenájdená</h2>
      <p className="fof-text">
        Hľadaná stránka neexistuje.
        <br />
        Skúste skontrolovať URL adresu, alebo prejdite <Link to="/">späť</Link>.
      </p>
      <img className="man-fof" src={Man} alt="muž čo nevie kde je" />
    </div>
  );
}

export default fof;