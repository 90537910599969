
import "./OdborCard.css"
import Card from "./Card/Card";
import { useQuery, gql } from "@apollo/client";

const OdborCard = () => {

  const GET_LOCATIONS = gql`
    {
      odbory {
        data {
          id
          attributes {
            title
            Image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;

  return (
    <div className="odbory-container">
      <div className="odbory">
        <div className="odbory-info">
          <h3 className="h3-odbory">Naše odbory</h3>
        </div>
        <div className="odbory-cards-parent">
          {data.odbory.data.map((item, index) => {
            return <Card key={item.id} index={index} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default OdborCard;
