import { motion } from "framer-motion";
import logo from "../../../assets/logo.svg";
import "./Apps.css";
import AppCard from "../AppCard/AppCard";
import { useQuery, gql } from "@apollo/client";
import {memo} from "react";

const Apps = () => {
  const GET_LOCATIONS = gql`
    {
      apps {
        data {
          attributes {
            name
            link
            icon {
              data {
                attributes {
                  url
                }
              }
            }
            instruction {
              description
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            odznak_app {
              data {
                attributes {
                  value
                  name
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;

  const variants = {
    hideLoadingScreen: {
      opacity: 0,
      transform: "translateX(100%)",
      transition: { duration: 1, ease: "easeInOut" },
    },
    bigLogo: {
      width: ["30rem", "20rem"],
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  return (
    <div className="apps-container">
      <motion.div
        className="loading-screen-finish"
        variants={variants}
        animate="hideLoadingScreen"
      >
        <motion.img
          src={logo}
          alt="logo školy"
          variants={variants}
          animate="bigLogo"
        />
      </motion.div>
      <h1 className="colorful">Žiacke aplikácie</h1>
      <div className="apps-container-content">
        {data.apps.data.map((item, index) => (
          <AppCard
            key={index}
            data={item}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
export default memo(Apps);
