import "./Contact.css";
import Phone from "../../assets/Phone.svg";
import Mail from "../../assets/Mail.svg";
const Contact = () => {
  return (
    <div className="contact_container">
      <div className="contact_header">
        <h1 className="colorful">Kontaktujte nás:</h1>
      </div>
      <div className="contact_content">
        <div className="contact_content_left">
          <div className="contact_content_left_top">
            <h2>Riaditeľ školy:</h2>
            <div className="contact_content_h3">
              <h3>Martin Rosina DiS.art</h3>
              <div className="card-small">
                <img src={Phone} alt="telefón" />
                <a href={"tel:0918 567 385"}>0918 567 385</a>
              </div>
              <div className="card-small">
                <img src={Mail} alt="Mail" />
                <a href={"mailto:martin.rosina@szustvrdosin.sk"}>
                  martin.rosina@szustvrdosin.sk
                </a>
              </div>
            </div>
          </div>
          <div className="contact_content_left_bottom">
            <h2>Adresa školy:</h2>
            <address className="contact_content_h3">
              <h3>
                Súkromná základná umelecká škola Tvrdošín
                <p className="contact_subtitle">
                  Medvedzie č.131 Tvrdošín 027 44 Slovenská republika
                </p>
              </h3>
            </address>
          </div>
        </div>
        <div className="contact_content_right">
          <div className="contact_content_right_top">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="maps"
                  id="gmap_canvas"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d650.04651706827!2d19.54887292924533!3d49.32969597271375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715b915c096d82d%3A0x97d4d59453ae76a5!2zU8O6a3JvbW7DoSB6w6FrbGFkbsOhIHVtZWxlY2vDoSDFoWtvbGEgVHZyZG_FoWlu!5e0!3m2!1sen!2sus!4v1659014464739!5m2!1sen!2sus"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
