import "./AboutBottom.css";
import Kids from "../../assets/kids.webp";
import Lines from "../../assets/lines2.svg";
import Button from "../Button/Button";
const AboutBottom = () => {
	return (
    <div className="about-bottom">
      <div className="about-bottom-text">
        <h3>
          Pridaj sa k nám,
          <br />
          <span className="colorful">splň si svoj sen!</span>
        </h3>
        <div className="about-bottom-text-content">
          <p>
            <strong>Klavír, husle, gitara, spev...</strong>
            <br />
            vyber si čo chceš
            <br />a príď k nám študovať umenie.
          </p>
          <Button
            link="prihlaska"
            color="#fff"
            border="none"
            background="linear-gradient(270deg, #f15a24 0%, #c52b2c 100%)"
          >
            Príď k nám
          </Button>
        </div>
      </div>
      <img
        className="bottom-main-image"
        alt="3 deti hrajúce na hudobné nástroje"
        src={Kids}
        width="1634"
        height="1327"
      />
      <img className="lines-bottom" src={Lines} alt="" />
    </div>
  );
}
export default AboutBottom;