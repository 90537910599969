import "./Footer.css";
import Facebook from "../../assets/Facebook.svg";
import YouTube from "../../assets/YouTube.svg";
import Instagram from "../../assets/Instagram.svg";

const Footer = () => {
  let date = new Date().getFullYear();
  return (
    <footer className="footer_container">
      <div className="footer_container_inside">
        <div className="footer_top">
          <div className="footer_card">
            <p className="footer-title">Kontakt:</p>
            <a href="mail:skola@szustvrdosin.sk">skola@szustvrdosin.sk</a>
            <a href="tel:0928 892 789">0928 892 789</a>
            <a href="tel:0928 892 789">0928 892 789</a>
          </div>
          <div className="footer_card footer_socials">
            <div>
              <img alt="Facebook" src={Facebook} />
              <a href="mail">Facebook</a>
            </div>
            <div>
              <img alt="Youtube" src={YouTube} />
              <a href="mail">YouTube</a>
            </div>
            <div>
              <img alt="Instagram" src={Instagram} />
              <a href="mail">Instagram</a>
            </div>
          </div>      
          <div className="footer_card">
            <p className="footer-title">Riaditeľ školy:</p>
            <p>Martin Rosina DiS.art</p>
            <a href="tel:0901 678 782">0901 678 782</a>
            <a href="mail:martin.rosina@szustvrdosin.sk">
              martin.rosina@szustvrdosin.sk
            </a>
          </div>
          <div className="footer_card">
            <p className="footer-title">Adresa školy:</p>
            <p>
              Súkromná základná umelcká škola
              <br />
              Medvedzie 131
              <br />
              Tvrdošín 02744
            </p>
          </div>
          <div className="footer_card">
            <p className="footer-title">Administrátor:</p>
            <p>Matej Koleštík</p>
            <a href="mail:admin@szustvrdosin.sk">admin@szustvrdosin.sk</a>
          </div>
          <div className="footer_card" style={{ borderRight: "0px" }}>
            <p className="footer-title">Iné:</p>
            <a href="https://www.osobnyudaj.sk/informovanie/42059062/sk/zakladne-informacie">
              Ochrana osobných údajov
            </a>
            <a href="/#" id="open_preferences_center">
              Cookies
            </a>
          </div>
        </div>
        <div className="footer_copyright">
          <p>© {date} | www.szustvrdosin.sk Všetky práva vyhradené.</p>
          <a href="mailto:matej@kolestik.com">
            Design & programovanie - Matej Koleštík
          </a>
        </div>
      </div>
    </footer>
  );
};;
export default Footer;
