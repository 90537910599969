import "./TeacherCard.css";
import { motion } from "framer-motion";
import Phone from "../../../assets/Phone.svg";
import Mail from "../../../assets/Mail.svg";
const TeacherCard = (props) => {
  const item = {
    openTeacher: {
      opacity: 1,
      x: 0,
    },
    closedTeacher: {
      opacity: 0,
      x: -20,
    },
  };
  return (
    <motion.div variants={item} className="teacher-card-odbor" href="/ucitelia">
      <p className="teacher-card-odbor-title">{props.data.attributes.name}</p>
      <p className="teacher-card-odbor-description-contact">Kontakt:</p>
      <div className="card-small margin-top-small-odbor">
        <img src={Phone} alt="telefón" />
        <a href={`tel:${props.data.attributes.phone}`}>
          {props.data.attributes.phone}
        </a>
      </div>
      <div className="card-small">
        <img src={Mail} alt="email" />
        <a href={`mailto:${props.data.attributes.email}`}>
          {props.data.attributes.email}
        </a>
      </div>
    </motion.div>
  );
};

export default TeacherCard;
