import "./MobileMenu.css";
import { Link, NavLink } from "react-router-dom";
import arrowDown from "../../../assets/arrow-down.svg";
import React, { useState } from "react";

const Submenu = (props) => {
  const [active, setActive] = useState(false);
  const style = {
    height: `${props.data.attributes.subheaders.data.length * 36.5}px`,
  };
  return (
    <li key={props.data.id} className="mobile-menu-item">
      <div className="menu-arrow">
        <NavLink
          onClick={() => {
            props.activeFunc(false);
          }}
          className="mobile-menu-link link"
          to={props.data.attributes.title
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replaceAll(" ", "-")}
        >
          {props.data.attributes.title}
        </NavLink>
        {props.data.attributes.subheaders.data.length > 0 && (
          <img
            onClick={() => {
              setActive(!active);
            }}
            className={`arrow-down-open ${active ? "" : "arrow-up-closed"}`}
            src={arrowDown}
            alt="šípka dole"
          />
        )}
      </div>
      <div
        className={`submenu ${active ? "" : "submenu-hidden"}`}
        style={active ? style : {}}
      >
        {props.data.attributes.subheaders.data.length > 0 && (
          <ul
            className={`arrow-styles active-arrow ${
              active ? "" : "non-active-arrow"
            }`}
          >
            {props.data.attributes.subheaders.data.map((data) => {
              return (
                <div
                  key={data.id}
                  onClick={() => {
                    props.activeFunc(false);
                  }}
                  className="submenu-link"
                >
                  <li className="submenu-item">
                    <NavLink
                      className="submenu-item"
                      to={`${data.attributes.header.data.attributes.title
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replaceAll(" ", "-")}/${data.attributes.title
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replaceAll(" ", "-")}`}
                    >
                      {data.attributes.title}
                    </NavLink>
                  </li>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    </li>
  );
};

export default Submenu;
