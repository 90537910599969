import Header from "./components/Header/Header";
import Blog from "./components/Blog/Blog";
import { Route, Routes } from "react-router-dom";
import "./styles/App.css";
import Home from "./components/Pages/Home";
import ToTop from "./ToTop";
import Teacher from "./components/Teachers/Teachers";
import Footer from "./components/Footer/Footer";
import HeaderBlog from "./components/Header/HeaderBlogs/HeaderBlog";
import Headerheader from "./components/Header/HeaderHeader/HeaderHeader";
import Contact from "./components/Contact/Contact";
import NewsAll from "./components/NewsAll/NewsAll";
import Fof from "./components/404/404";
import Apps from "./components/Apps/Apps/Apps";
import AppButton from "./components/Apps/AppsButton";
import OdborBlog from "./components/OdborBlog/OdborBlog";
import { useQuery, gql } from "@apollo/client";
import Custom from "./components/Custom/Custom";
import Photoalbum from "./components/Photoalbum/Photoalbum";
import NotyMainPage from "./components/Noty/NotyMainPage";
import Composer from "./components/Noty/Composer/Composer";
import NotyPage from "./components/Noty/NotyPage/NotyPage";
import Register from "./components/Register/Register";
import Mascot from "./components/Mascot/Mascot";
import LoginRedirect from "./components/Login/LoginRedirect";
import { useCookies } from "react-cookie";

function App() {
  const GET_LOCATIONS = gql`
    {
      odbory {
        data {
          id
          attributes {
            title
          }
        }
      }
      headers {
        data {
          id
          attributes {
            title
            subheaders {
              data {
                id
                attributes {
                  title
                  header {
                    data {
                      attributes {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);
    const [cookies,] = useCookies();
localStorage.setItem("jwt", `${cookies.jwt}`);
  if (loading) return null;
  if (error) return <p>Error :</p>;
  return (
    <div className="app">
      <Header />
      <Mascot />
      <main>
        <Routes>
          <Route path="/fotoalbum" element={<Photoalbum />} />
          <Route path="/" element={<Home />} />
          <Route path="/aktuality" element={<NewsAll />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="*" element={<Fof />} />
          <Route path="/register" element={<Register />} />
          <Route path="/custom/:slug" element={<Custom />} />

          {data.headers.data.map((item) => {
            return (
              <Route
                key={item.id}
                path={`/${item.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "")}`}
                element={
                  <Headerheader
                    title={item.attributes.title}
                    id={item.id}
                    key={item.id}
                  />
                }
              />
            );
          })}

          {data.headers.data.map((headerItem) => {
            return headerItem.attributes.subheaders.data.map((item) => {
              return (
                <Route
                  key={item.id}
                  path={`/${item.attributes.header.data.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}/${item.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}`}
                  element={
                    <HeaderBlog
                      blogData={item}
                      data={headerItem}
                      key={item.id}
                    />
                  }
                />
              );
            });
          })}
          <Route path="/odbor/:slug" element={<OdborBlog />} />
          <Route path="/sheet-music/:slug" element={<NotyPage />} />
          <Route path="/skladatel/:slug" element={<Composer />} />
          <Route path="/ucitelia" element={<Teacher key={8} />} />
          <Route path="/aplikacie" element={<Apps />} />
          <Route path="/blog/:slug" element={<Blog />} />
          <Route path="/noty" element={<NotyMainPage />} />
          <Route
            path="/connect/:provider/redirect"
            element={<LoginRedirect />}
          />
        </Routes>
      </main>
      <Footer />
      <AppButton />
      <ToTop />
    </div>
  );
}

export default App;
