import "./Apps/Apps.css";
import { memo } from "react";
const TypeBadge = (props) => {
  const backgroundColor = () => {
    if (props.data.attributes.odznak_app.data === null) {
      return "transparent";
    }
    if (props.data.attributes.odznak_app.data !== null) {
      return props.data.attributes.odznak_app.data.attributes.value;
    }
  };
  const name = () => {
    if (props.data.attributes.odznak_app.data === null) {
      return null;
    }
    if (props.data.attributes.odznak_app.data !== null) {
      return props.data.attributes.odznak_app.data.attributes.name;
    }
  };
  
  return (
    <div
      className="type-badge"
      style={{
        background: backgroundColor(),
      }}
    >
      <p className="">{name()}</p>
    </div>
  );
};

export default memo(TypeBadge);
