import React, { useState } from "react";

const Badge = (props) => {
  const [hover, setHover] = useState(false);
  let color = "";

  const fileSize = (size) =>{
    if(size < 1024){
      return size + " KB";
    }
    else if(size < 1024 * 1024){
      return (size / 1024).toFixed(2) + " MB";
    }
    else if(size < 1024 * 1024 * 1024){
      return (size / 1024 / 1024).toFixed(2) + " GB";
    }
    else{
      return (size / 1024 / 1024 / 1024).toFixed(2) + " TB";
    }
  }
  switch (props.data.attributes.ext.substring(1).toLowerCase()) {
    case "jpg":
      color = "FF521F";
      break;
    case "jpeg":
      color = "FF521F";
      break;
    case "png":
      color = "8CB5FA";
      break;
    case "gif":
      color = "E0B62D";
      break;
    case "bmp":
      color = "3BEBD0";
      break;
    case "svg":
      color = "F5B500";
      break;
    case "webp":
      color = "0D0E4E";
      break;
    case "ico":
      color = "4E0A56";
      break;
    case "tif":
      color = "2EE0A7";
      break;
    case "tiff":
      color = "2EE0A7";
      break;
    case "psd":
      color = "2B85E0";
      break;
    case "ai":
      color = "FFB643";
      break;
    case "eps":
      color = "EB6600";
      break;
    case "docx":
      color = "3787EB";
      break;
    case "doc":
      color = "3787EB";
      break;
    case "xlsx":
      color = "26B159";
      break;
    case "xls":
      color = "26B159";
      break;
    case "pptx":
      color = "F66F08";
      break;
    case "ppt":
      color = "F66F08";
      break;
    case "pdf":
      color = "FF3F35";
      break;
    case "zip":
      color = "8A0111";
      break;
    case "rar":
      color = "8A0111";
      break;
    case "mp4":
      color = "72CFF5";
      break;
    case "mp3":
      color = "00F5AD";
      break;
    case "txt":
      color = "858585";
      break;
    default:
      color = "212121";
  }
  const handleMouseEnter = () => {
    if (hover === true) {
      return {
        color: `${color}`,
        transform: `translateY(-3px) translateX(3px)`,
        boxShadow: `-3px 3px 0 0 #${color}`,
        border: `2px solid #${color}`,
      };
    } else if (hover === false) {
      return {
        color: `#${color}`,
        transform: `translateY(0px)`,
        border: `2px solid #${color}`,
      };
    }
  };
  return (
    <li
      key={props.data.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <a
        className="link-file-a"
        href={`${process.env.REACT_APP_API}${props.data.attributes.url}`}
        target="_blank" rel="noreferrer" 
      >
        <div className="file-badge" style={handleMouseEnter()}>
          <p>{props.data.attributes.ext.substring(1).toLowerCase()}</p>
        </div>
        <div className="file-info">
          <p
            style={{
              textDecoration: hover ? "underline" : "none",
              fontWeight: hover ? "bold" : "normal",
            }}
            className="filename"
          >
            {props.data.attributes.name.replace(props.data.attributes.ext, "")}
          </p>
          <p className="filesize">
            {fileSize(props.data.attributes.size)}
          </p>
        </div>
      </a>
    </li>
  );
};
export default Badge;
