import "./ShareMenu.css";
const ShareMenu = ({ data, shareMenu, setShareMenu }) => {
  const email = `
<!DOCTYPE html>
<html>
<body>
<img src="${process.env.REACT_APP_API}${data.File.data.attributes.url}" alt="Random online image">
</body>
</html> 
	  `;
  return (
    <div className="share-menu-container">
        <section className="share-menu-banner">
          <p className="share-menu-heading">Zdieľať</p>
          <div className="share-menu-sharer">
            <a
              href={`mailto:test@example.com?subject=${data.Name}&body=${email}`}
            >
              E-Mail
            </a>
          </div>
          <div className="share-menu-sharer">
            <a href={`mai`}>Facebook</a>
          </div>
          <div className="share-menu-sharer">
            <a
              href={`mailto:test@example.com?subject=${data.Name}&body=${email}`}
            >
              Link
            </a>
          </div>
        </section>
      <div onClick={() => setShareMenu(false)} className="share-menu-background" />
    </div>
  );
};
export default ShareMenu;
