import "./BottomInfo.css";
import Users from "../../assets/users.svg";
import Money from "../../assets/money.svg";
import Phone from "../../assets/call.svg";
import Clipboard from "../../assets/clipboard.svg";
import Button from "../Button/Button";

const BottomInfo = () => {
  return (
    <div className="bottom-info">
      <div className="bottom-info_content">
        <div className="bottom-info_card">
          <div className="bottom-info_card-image_container">
            <img
              width="50"
              height="50"
              loading="lazy"
              className="bottom-info_image"
              src={Users}
              alt="učitelia ikonka"
            />
          </div>
          <h4>Učitelia</h4>
          <p className="bottom-info_text">
            Zoznam a kontakty na naších učiteľov.
          </p>
          <Button link="ucitelia" color="#3c3c3c" background="none">
            Zoznam učiteľov
          </Button>
        </div>
        <div className="bottom-info_card">
          <div className="bottom-info_card-image_container">
            <img
              width="50"
              height="50"
              loading="lazy"
              className="bottom-info_image"
              src={Phone}
              alt="učitelia ikonka"
            />
          </div>
          <h4>Kontakt</h4>
          <p className="bottom-info_text">
            Kontakt na našu školu a poloha školy na mape.
          </p>
          <Button link="kontakt" color="#3c3c3c">
            Kontakt
          </Button>
        </div>
        <div className="bottom-info_card">
          <div className="bottom-info_card-image_container">
            <img
              width="50"
              height="50"
              loading="lazy"
              className="bottom-info_image"
              src={Clipboard}
              alt="učitelia ikonka"
            />
          </div>
          <h4>Prihláška</h4>
          <p className="bottom-info_text">
            Tu môžete podať prihlášku na našu školu.
          </p>
          <Button link="ucitelia" color="#3c3c3c" background="none">
            Prihláška
          </Button>
        </div>
        <div className="bottom-info_card">
          <div className="bottom-info_card-image_container">
            <img
              width="50"
              height="50"
              loading="lazy"
              className="bottom-info_image"
              src={Money}
              alt="učitelia ikonka"
            />
          </div>
          <h4>2% z daní</h4>
          <p className="bottom-info_text">
            Ak nás chcete podporiť, môžete nám podarovať 2% z daní.
          </p>
          <Button link="ucitelia" color="#3c3c3c" background="none">
            Podporiť
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BottomInfo;
