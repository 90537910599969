import "./Button.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const [translateX, setTranslateX] = useState("-10px");
  const [arrow, setArrow] = useState();
  const [margin, setMargin] = useState("0px"); 

  const styles = {
    transform: `translateX(${translateX})`,
    transition: "transform 0.2s ease-in-out",
    stroke: `${props.color} !important`,
    color: `${props.color}`,
  };
  const stylePadding = {
    color: `${props.color}`,
    background: `${props.background}`,
    border: `${props.border}`
  };
  const arrowStyle = {
    visibility: "visible",
    opacity: 1,
    fill: "#fff",
    stroke: `${props.color}`,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "3px",

  };
  return (
    <Link
    to={props.link}
      onMouseEnter={() => {
        setTranslateX("-5px");
        setArrow(arrowStyle);
        setMargin("10px");
      }}
      onMouseLeave={() => {
        setTranslateX("-10px");
        setArrow();
        setMargin("0px");
      }}
      className="button"
      style={stylePadding}
    >
      <p style={{marginLeft: margin}} className="button_content">{props.children}</p>

      <svg
        style={styles}
        className="arrow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.63 16.09"
      >
        <g id="a" />
        <g id="b">
          <g id="c">
            <polyline
              points="14.59 1.5 21.13 8.04 14.59 14.59"
              style={{
                fill: "none",
                stroke: `${props.color}`,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "3px",
              }}
            />
            <line
              className="arrow-line"
              style={arrow}
              x1="21.13"
              y1="8.04"
              x2="7.13"
              y2="8.04"
            />
          </g>
        </g>
      </svg>
    </Link>
  );
};

export default Button;
