import "./Register.css";
import { useMutation, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Mail from "../lotties/mail.json";
import mail from "../../assets/zavinac.svg";
import user from "../../assets/user.svg";
import key from "../../assets/key.svg";
import logo from "../../assets/logo_small.svg";
import Error from "../Error/Error";
import microsoft from "../../assets/microsoft.svg";
import google from "../../assets/google.svg";

const Register = ({ setRegister, setClicked, setLogin }) => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      y: -100,
      transition: {
        duration: 0.3,
      },
    },
  };

  const background = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };
  const successDialog = {
    initial: {
      height: 0,
      opacity: 0,
    },
    animate: {
      height: "auto",
      opacity: 1,
    },
    exit: {
      height: 0,
      opacity: 0,
    },
  };
  const [formState, setFormState] = useState({
    username: "",
    email: "",
    password: "",
  });

  const USER_REGISTRATION = gql`
    mutation ($email: String!, $password: String!, $username: String!) {
      register(
        input: { username: $username, email: $email, password: $password }
      ) {
        jwt
        user {
          username
          email
        }
      }
    }
  `;
  const [email, setEmail] = useState(false);
  const [createRegistration, { data, error }] = useMutation(USER_REGISTRATION, {
    variables: {
      username: formState.username,
      email: formState.email,
      password: formState.password,
    },
  });
  return (
    <motion.div
      variants={background}
      initial="initial"
      animate="animate"
      exit="exit"
      className="register-container"
    >
      {(error != null || undefined) && <Error message={error} />}
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="register-box"
      >
        <AnimatePresence>
          {(data === undefined || null) && email !== true && (
            <motion.div
              variants={successDialog}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <div className="register-image-container">
                <img
                  src={logo}
                  alt="logo školy"
                  className="logo-small-registration"
                />
              </div>
              <h1>Vytvor si účet</h1>
              <div className="social-login-container">
                <a href={`${process.env.REACT_APP_API}/api/connect/microsoft`}>
                  <img
                    src={microsoft}
                    alt="microsoft"
                    className="socal-login"
                  />
                  <p>Registrácia pomocou Microsoft</p>
                </a>
                <a href={`${process.env.REACT_APP_API}/api/connect/google`}>
                  <img src={google} alt="google" className="socal-login" />
                  <p>Registrácia pomocou Google</p>
                </a>
              </div>
              <div
                onClick={() => setEmail(true)}
                className="email-login-button"
              >
                <div className="regstration-mail">
                  <img src={mail} alt="mail" className="socal-login" />
                  <p>Zaregistovať sa s E-mailom</p>
                </div>
              </div>
              <div>
                <span className="register-form-have-account">
                  <p>
                    Už máš účet?{" "}
                    <span
                      onClick={() => setLogin(true) || setRegister(false)}
                      href="/login"
                    >
                      Prihlás sa
                    </span>
                  </p>
                </span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {email === true && data === undefined && (
            <motion.div
              variants={successDialog}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <div className="register-image-container">
                <img
                  src={logo}
                  alt="logo školy"
                  className="logo-small-registration"
                />
              </div>
              <h1>Vytvor si účet</h1>
              <motion.form
                onSubmit={(e) => {
                  e.preventDefault();
                  createRegistration();
                }}
                className="email-login-container"
              >
                <div className="register-form-input">
                  <input
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        username: e.target.value,
                      })
                    }
                    required={true}
                    type="text"
                    placeholder="Používateľské meno"
                  />
                  <img
                    className="small-registration-image"
                    src={user}
                    alt="mail"
                  />
                </div>
                <div className="register-form-input">
                  <input
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        email: e.target.value,
                      })
                    }
                    required={true}
                    type="text"
                    placeholder="email"
                  />
                  <img
                    className="small-registration-image"
                    src={mail}
                    alt="mail"
                  />
                </div>
                <div className="register-form-input">
                  <input
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        password: e.target.value,
                      })
                    }
                    required={true}
                    type="password"
                    placeholder="heslo"
                  />
                  <img
                    className="small-registration-image"
                    src={key}
                    alt="mail"
                  />
                </div>
                <button type="submit">Registrovať sa</button>
              </motion.form>
              <div
                onClick={() => setEmail(false)}
                className="email-login-button"
              >
                <div className="regstration-mail">
                  <img src={user} alt="mail" className="socal-login" />
                  <p>Zaregistovať sa so Sociálnými médiami</p>
                </div>
              </div>
              <div>
                <span className="register-form-have-account">
                  <p>
                    Už máš účet?{" "}
                    <span
                      onClick={() => setLogin(true) || setRegister(false)}
                      href="/login"
                    >
                      Prihlás sa
                    </span>
                  </p>
                </span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {data !== undefined && data !== null && (
            <motion.div
              variants={successDialog}
              exit="exit"
              initial="initial"
              animate="animate"
              className="registration-success "
            >
              <div className="register-image-container">
                <img
                  src={logo}
                  alt="logo školy"
                  className="logo-small-registration"
                />
              </div>
              <h1>Ďakujeme za registráciu</h1>
              <Player src={Mail} autoplay={true} loop={true}>
                <Controls visible={false} />
              </Player>
              <p>Skontroluj si prosím email a potvrď registráciu</p>
              <button onClick={() => setRegister(false) || setClicked(false)}>
                Zatvoriť
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <div className="register-back" onClick={() => setRegister(false)} />
    </motion.div>
  );
};
export default Register;
