import "./Type.css";
import React from "react";
const Type = (props) => {
  if (props.type === "Novinka") {
    return <div className="news-type-novinka">Novinka</div>;
  } else if (props.type === "Oznam") {
    return <div className="news-type-oznam">Oznam</div>;
  } else if (props.type === "Odbor") {
    return <div className="news-type-odbor">Odbor</div>;
  }
};
export default Type;
