import React, { useState } from "react";

const Badge = (props) => {
  const [hover, setHover] = useState(false);
let color = "fff"
  const handleMouseEnter = () => {
    if (hover === true) {
      return {
        color: `${color}`,
        transform: `translateY(-3px) translateX(3px)`,
        boxShadow: `-3px 3px 0 0 #${color}`,
        border: `2px solid #${color}`,
      };
    } else if (hover === false) {
      return {
        color: `#${color}`,
        transform: `translateY(0px)`,
        border: `2px solid #${color}`,
      };
    }
  };
    const fileSize = (size) => {
      if (size < 1024) {
        return size + " KB";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + " MB";
      } else if (size < 1024 * 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " GB";
      } else {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " TB";
      }
    };
  return (
    <li
      key={props.data.id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <a
        className="link-file-a"
        href={`${process.env.REACT_APP_API}${props.data.attributes.url}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="file-badge" style={handleMouseEnter()}>
          <p>{props.data.attributes.ext.substring(1).toLowerCase()}</p>
        </div>
        <div className="file-info">
          <p
            style={{
              textDecoration: hover ? "underline" : "none",
              fontWeight: hover ? "bold" : "normal",
            }}
            className="filename"
          >
            {props.data.attributes.name.replace(props.data.attributes.ext, "")}
          </p>
          <p className="filesize">{fileSize(props.data.attributes.size)}</p>
        </div>
      </a>
    </li>
  );
};
export default Badge;
