import "./Teachers.css";
import Phone from "../../assets/Phone.svg";
import Mail from "../../assets/Mail.svg";
import Hat from "../../assets/hat.svg";
import { useQuery, gql } from "@apollo/client";
const Teachers = () => {
  const GET_LOCATIONS = gql`
    {
      teachers {
        data {
          id
          attributes {
            name
            photo {
              data {
                attributes {
                  url
                }
              }
            }
            email
            zamestnanie
            phone
            hudobne_nastrojes {
              data {
                attributes {
                  predmet
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;

  return (
    <div className="teacher_container">
      <h3 className="colorful">Pedagogický zbor</h3>
      <div className="teacher-card_container">
        <div className="teacher-card_container_container">
          {data.teachers.data.map((item) => {
            /////////////////////////
            const zamestnanie = () => {
              if (item.attributes.zamestnanie === null) {
                return <p className="teacher-title">&nbsp;</p>;
              } else {
                return (
                  <p className="teacher-title">{item.attributes.zamestnanie}</p>
                );
              }
            };
            /////////////////////////
            const phone = () => {
              if (item.attributes.phone === "" || null) {
                return <p className="teacher-phone">&nbsp;</p>;
              } else {
                return (
                  <div className="card-small">
                    <img src={Phone} alt="telefón" />
                    <a href={"tel:" + item.attributes.phone}>
                      {item.attributes.phone}
                    </a>
                  </div>
                );
              }
            };
            /////////////////////////
            return (
              <div className="teacher-card-parent" key={item.id.toString()}>
                <div className="teacher-card">
                  <img
                    className="teacher-photo"
                    src={
                      process.env.REACT_APP_API +
                      item.attributes.photo.data.attributes.url
                    }
                    alt={item.attributes.name}
                  />
                  <div className="teacher-info_container">
                    <p className="teacher-name">{item.attributes.name}</p>
                    {zamestnanie()}
                    {phone()}
                    <div className="card-small">
                      <img src={Mail} alt="Mail" />
                      <a href={"mailto:" + item.attributes.email}>
                        {item.attributes.email}
                      </a>
                    </div>
                    <div className="card-small">
                      <img src={Hat} alt="Maturitná čiapka" />
                      <p>
                        {item.attributes.hudobne_nastrojes.data.map(
                          (x, i, arr) => {
                            if (arr.length - 1 === i) {
                              return x.attributes.predmet;
                            } else {
                              return x.attributes.predmet + ", ";
                            }
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Teachers;
