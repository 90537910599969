import "./Login.css";
import { useMutation, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import successLottie from "../lotties/success.json";
import mail from "../../assets/zavinac.svg";
import key from "../../assets/key.svg";
import logo from "../../assets/logo_small.svg";
import user from "../../assets/user.svg";
import Error from "../Error/Error";
import { useNavigate } from "react-router-dom";
const Login = ({ setLogin, setClicked, setRegister }) => {
  const variants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      y: -100,
      transition: {
        duration: 0.3,
      },
    },
  };

  const background = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };
  const success = {
    exit: {
      position: "absolute",
      opacity: 0,
      x: 100,
      zIndex: 3,
      transition: {
        duration: 0.3,
      },
    },
  };
  const successDialog = {
    initial: {
      zIndex: 2,
      position: "absolute",
      opacity: 0,
      x: -100,
      transition: {
        duration: 0.3,
      },
    },
    animate: {
      position: "relative",
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: 100,
      transition: {
        duration: 0.3,
      },
    },
  };
  const [, setCookie] = useCookies(["jwt"]);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const USER_REGISTRATION = gql`
    mutation ($email: String!, $password: String!) {
      login(input: { identifier: $email, password: $password }) {
        jwt
        user {
          id
          username
        }
      }
    }
  `;

  const [createRegistration, { data, error }] = useMutation(USER_REGISTRATION, {
    variables: {
      email: formState.email,
      password: formState.password,
    },
  });

  useEffect(() => {
    setCookie("jwt", data?.login.jwt, { path: "/", maxAge: 60 * 60 * 24 * 7 });
    setCookie("username", data?.login.user.username, {
      path: "/",
    });
  }, [data]);

  const refreshPage = () => {
    setTimeout(() => {
      navigate(0);
    }, 300);
  };

  return (
    <motion.div
      variants={background}
      initial="initial"
      animate="animate"
      exit="exit"
      className="register-container"
    >
      {(error != null || undefined) && <Error message={error} />}
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="register-box"
      >
        <AnimatePresence>
          {(data === undefined || null) && (
            <motion.div variants={success} exit="exit">
              <div className="register-image-container">
                <img
                  src={logo}
                  alt="logo školy"
                  className="logo-small-registration"
                />
              </div>
              <h1>Prihlásenie</h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  createRegistration();
                }}
              >
                <div className="register-form-input">
                  <input
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        email: e.target.value,
                      })
                    }
                    required={true}
                    type="text"
                    placeholder="email alebo používateľské meno"
                  />
                  <div className="login-form-input-image">
                    <img src={mail} alt="mail" />
                    <img src={user} alt="register" />
                  </div>
                </div>
                <div className="register-form-input">
                  <input
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        password: e.target.value,
                      })
                    }
                    required={true}
                    type="password"
                    placeholder="heslo"
                  />
                  <img
                    className="small-registration-image"
                    src={key}
                    alt="mail"
                  />
                </div>
                <button type="submit">Prihlásiť sa</button>
              </form>
              <span className="register-form-have-account">
                <p>
                  Zabudol si heslo? <span>Tu si ho môžeš zresetovať</span>
                </p>
                <p>
                  Ešte si sa nezaregistroval?{" "}
                  <span onClick={() => setLogin(false) || setRegister(true)}>
                    Registruj sa tu
                  </span>
                </p>
              </span>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {data !== undefined && data !== null && (
            <motion.div
              variants={successDialog}
              exit="exit"
              initial="initial"
              animate="animate"
              className="registration-success"
            >
              <div className="register-image-container">
                <img
                  src={logo}
                  alt="logo školy"
                  className="logo-small-registration"
                />
              </div>
              <h1>Úspešne si prihlásený</h1>
              <Player src={successLottie} autoplay={true} keepLastFrame={true}>
                <Controls visible={false} />
              </Player>
              <p>
                Teraz môžeš toto okno zatvoriť a využívať túto stránku naplno.
              </p>
              <button
                onClick={() =>
                  refreshPage() || setLogin(false) || setClicked(false)
                }
              >
                Zatvoriť
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <div
        className="register-back"
        onClick={() => refreshPage() || setLogin(false)}
      />
    </motion.div>
  );
};
export default Login;
