import News from "../News/NewsCards/News";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { memo } from "react";
const NewsAll = () => {


const GET_LOCATIONS = gql`
  {
    blogs(sort: "createdAt:desc") {
      data {
        id
        attributes {
          title
          type
          createdAt
          Cover {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const { loading, error, data } = useQuery(GET_LOCATIONS);

if (loading) return null;
if (error) return <p>Error :</p>;

  return (
    <div
      className="news-container-big"
      style={{ paddingTop: "calc(80px + 2.5%)" }}
    >
      <Helmet>
        <title>Aktuality | Súkromná základná umelecká škola Tvrdošín</title>
      </Helmet>
      <div className="news-container">
        {data.blogs.data.map((item) => {
          return <News key={item.id} data={item} />;
        })}
      </div>
    </div>
  );
};

export default memo(NewsAll);
