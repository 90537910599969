import "../Blog/Blog.css";
import "./OdborBlog.css";
import Type from "../News/Type/Type";
import File from "../File/File";
import Markdown from "markdown-to-jsx";
import Photo from "../Photo/Photo";
import Video from "../Video/Video";
import OdborCardSmall from "./OdborCardSmall/OdborCardSmall";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const OdborBlog = () => {
  let { slug } = useParams();
  const GET_LOCATIONS = gql`
    {
      odbory(filters: { slug: { eq: "${slug}" } }) {
        data {
          id
          attributes {
            title
            content
            createdAt
            pictures {
              data {
                id
                attributes {
                  url
                }
              }
            }
            videos{
              data{
                id
                attributes{
                  url
                }
              }
            }
            files {
              __typename
              ... on ComponentFileFile {
                name
                id
                file(pagination:{pageSize: 10000}) {
                  data {
                    id
                    attributes {
                      size
                      name
                      ext
                      url
                    }
                  }
                }
              }
            }
            predmets {
              data {
                id
                attributes {
                  predmet                
                  teachers {
                    data {
                      id
                      attributes {
                        name
                        email
                        phone
                        photo {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                  odbor {
                    data {
                      id
                      attributes {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);
  if (loading) return null;
  if (error) return <p>Error :</p>;

  const dateOld = Date.parse(data.odbory.data[0].attributes?.createdAt);

  const files = () => {
    if (data.odbory.data[0].attributes?.files === null) {
      return;
    } else {
      return data.odbory.data[0].attributes?.files.map((item) => {
        return (
          <div className="file" key={item.id}>
            <h2 className="h3-files">{item.Name}</h2>
            <File data={item.file} />
          </div>
        );
      });
    }
  };

  const topbar = () => {
    return (
      <div className="odbor-top">
        <p>
          Pridané: <time>{new Date(dateOld).toLocaleDateString("sk-SK")}</time>
        </p>
        <Type type={"Odbor"} />
      </div>
    );
  };

  const photos = () => {
    if (data.odbory.data[0].attributes?.pictures.data.length === 0) {
      return;
    } else {
      return (
        <div className="photo-container_main">
          <h2 className="h3-files">Fotky:</h2>
          <div className="photo-container">
            <Photo data={data.odbory.data[0].attributes?.pictures.data} />
            <Video data={data.odbory.data[0].attributes?.videos.data} />
          </div>
        </div>
      );
    }
  };

  const Teacher = () => {
    if (data.odbory.data[0].attributes?.predmets.data.length !== 0) {
      return (
        <div className="odbor-predmet-container">
          <h2 className="h3-files">Predmety</h2>
          {data.odbory.data[0].attributes?.predmets.data.map((data) => {
            return <OdborCardSmall key={data.id} data={data} />;
          })}
        </div>
      );
    } else return;
  };

  return (
    <div className="main-odbor_container">
      <div className="odbor-main_content">
        <div className="content_container">
          <Helmet>
            <title>
              {" "}
              {data.odbory.data[0].attributes?.title} | Súkromná základná
              umelecká škola Tvrdošín
            </title>
          </Helmet>
          {topbar()}
          {/*breadcrumbs*/}
          <ul className="breadcrumbs">
            <li>
              <a href="/">{`Úvod >`}</a>
            </li>
            <li>
              <a
                href={`/odbor/${data.odbory.data[0].attributes?.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}`}
              >
                {data.odbory.data[0].attributes?.title}
              </a>
            </li>
          </ul>
          {/*breadcrumbs end*/}
          <article className="title_content">
            <h1 className="colorful odbor-header">
              {data.odbory.data[0].attributes?.title}
            </h1>
            <Markdown className="blog-text">
              {data.odbory.data[0].attributes?.content}
            </Markdown>
          </article>
        </div>
        <div className="others_container">
          {Teacher()}
          {photos()}
          {files()}
        </div>
      </div>
    </div>
  );
};
export default OdborBlog;
