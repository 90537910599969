import "./Main.css";
import Button from "../Button/Button";
import Instruments from "../../assets/instruments.webp";
import Lines from "../../assets/lines.svg";
import {Helmet} from "react-helmet-async";

const Main = () => {
 
  return (
    <div className="mainPart">
      <Helmet>
        <title>Súkromná základná umelecká škola Tvrdošín</title>
      </Helmet>
      <div className="mainText_Container">
        <h1 className="mainText_Header">
          Súkromná základná <br />
          umelecká škola <br />
          Tvrdošín
        </h1>
        <h2 className="description">
          Naša škola je vybavená tým
          <br />
          najmodernejším vybavením a najlepšími
          <br />
          hudobnými nástojmi.
        </h2>
        <div className="button-cluster_main">
          <Button
            link="prihlaska"
            color="#fff"
            border="none"
            background="linear-gradient(270deg, #f15a24 0%, #c52b2c 100%)"
          >
            Prihláška na našu školu
          </Button>
          <div className="button-left">
            <Button link="kontakt" color="#3c3c3c" background="none">
              Kontakt
            </Button>
          </div>
        </div>
      </div>
      <img
        rel="preload"
        as="image"
        className="instruments"
        src={Instruments}
        alt="hudobné nástroje"
      />
      <img className="lines-hero" alt="" src={Lines} />
    </div>
  );
};

export default Main;
