import "./Photoalbum.css";
import Fancybox from "../../fancybox.js";
import {motion} from "framer-motion";
const Photo = ({ url }) => {
	const image = {
		hover:{
			scale: 1.05,
			y: -10,
			boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)"
		}
	}
  return (
    <div className="photoalbum-photo">
      <Fancybox>
        <motion.img
          variants={image}
          whileHover="hover"
          data-fancybox="gallery"
          src={`${process.env.REACT_APP_API}${url}`}
          alt="fotka"
        />
      </Fancybox>
    </div>
  );
};
export default Photo;
