import "./MobileMenu.css";
import X from "../../../assets/x.svg";
import Submenu from "./Submenu";
import { Link } from "react-router-dom";

const MobileMenu = (props) => {
  return (
    <div
      className={`background-menu ${
        props.active ? "" : "background-menu-hidden"
      }`}
    >
      <div
        onClick={() => {
          props.activeFunc(false);
        }}
        className={`onclick_div ${
          props.active
            ? "mobile-menu_background"
            : "mobile-menu_background_hidden"
        }`}
      >
        &nbsp;
      </div>
      <div
        className={`mobile-menu ${props.active ? "" : "mobile-menu-hidden"}`}
      >
        <div className={"x"} onClick={props.ham}>
          <img className="warning-close" src={X} alt="zatvoriť" />
        </div>
        <ul className="mobile-menu-list">
          {props.data.data.map((item) => {
            return (
              <Submenu
                key={item.id}
                data={item}
                activeFunc={props.activeFunc}
              />
            );
          })}
          <li
            onClick={() => {
              props.activeFunc(false);
            }}
            className="mobile-menu-item"
          >
            <Link className="mobile-menu-link link colorful" to="/aktuality">
              Aktuality
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default MobileMenu;
