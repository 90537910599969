import "./NotyMainPage.css";
import { useQuery, gql } from "@apollo/client";
import ComposerCard from "./ComposerCard/ComposerCard";

const GET_LOCATIONS = gql`
  {
    skladatels {
      data {
        attributes {
          Name
          Slug
          Photo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
const NotyMainPage = () => {
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="NotyMainPage">
      <h1 className="colorful odbor-header">Skladatelia</h1>
      <div className="noty-container-main">
        {data.skladatels.data.map((item, index) => {
          return <ComposerCard key={index} data={item} />;
        })}
      </div>
    </div>
  );
};

export default NotyMainPage;
