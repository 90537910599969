import "./NotAuth.css";
import noAuthLottie from "../../lotties/noauth.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
const NotAuth = () => {
	  return (
      <div className="not-auth-container">
        <h1>Aby ste sa dostali na túto stránku tak sa musíte prihlásiť.</h1>
        <Player src={noAuthLottie} autoplay={true} keepLastFrame={true} className="not-auth-lottie">
          <Controls visible={false} />
        </Player>
      </div>
    );
}
export default NotAuth;