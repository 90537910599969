import "../HeaderBlogs/HeaderBlog.css";
import Type from "../../News/Type/Type";
import HeaderFile from "../HeaderBlogs/HeaderFile";
import Markdown from "markdown-to-jsx";
import { Link, NavLink } from "react-router-dom";
import Photo from "../../Photo/Photo";
import "./HeaderHeader.css";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import Video from "../../Video/Video";

const HeaderHeader = (props) => {
  const GET_LOCATIONS = gql`
    {
      header(id: ${props.id}) {
        data {
          attributes {
            title
            content
            pictures {
              data {
                id
                attributes {
                  url
                }
              }
            }
            videos{data{id attributes{url}}}
            subheaders {
              data {
                id
                attributes {
                  title
                }
              }
            }
            publishedAt
            files {
              __typename
              ... on ComponentFileFile {
                id
                name
                file(pagination:{pageSize: 10000}) {
                  data {
                    id
                    attributes {
                      size
                      name
                      url
                      ext
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);
  if (loading) return null;
  if (error) return <p>Error :</p>;

  const dateOld = Date.parse(data.header.data.attributes.publishedAt);

  const files = () => {
    if (data.header.data.attributes.files === null) {
      return;
    } else {
      return data.header.data.attributes.files.map((item) => {
        return (
          <div key={item} className="file">
            <h2 className="h3-files">{item.Name}</h2>
            <HeaderFile data={item.file} />
          </div>
        );
      });
    }
  };

  const sidebar = () => {
    return (
      <div className="sidebar_container sidebar_container_main_header">
        <div className="sidebar sidebar_main_header">
          <h4 className="sidebar_item more_results colorful">
            Podobné výsledky
          </h4>
          {data.header.data.attributes.subheaders.data.map((item) => {
            return (
              <div key={item.id} className="sidebar_item">
                <NavLink
                  className=" link"
                  to={`/${data.header.data.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}/${item.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}`}
                >
                  {item.attributes.title}
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const topbar = () => {
    if (
      window.location.pathname.substring(0, 6) === "/odbor" ||
      window.location.pathname.substring(0, 5) === "/blog"
    ) {
      return (
        <div className="odbor-top">
          {<p>Pridané: {new Date(dateOld).toLocaleDateString("sk-SK")}</p>}
          <Type type={data.header.data.attributes.type} />
        </div>
      );
    }
  };
  const photos = () => {
    if (
      data.header.data.attributes.pictures.data.length === 0 &&
      data.header.data.attributes.videos.data.length === 0
    ) {
      return;
    } else {
      return (
        <div className="photo-container_main">
          <h2 className="h3-files">Fotky:</h2>
          <div className="photo-container">
            <Photo data={data.header.data.attributes.pictures.data} />
            <Video data={data.header.data.attributes.videos.data} />
          </div>
        </div>
      );
    }
  };
  const content = () => {
    if (data.header.data.attributes.content === null) {
      return;
    } else {
      return (
        <Markdown className="blog-text">
          {data.header.data.attributes.content}
        </Markdown>
      );
    }
  };
  const items = () => {
    if (window.innerWidth < 1020) {
      return (
        <div className="mobile-menu-item_container">
          {data.header.data.attributes.subheaders.data.map((item) => {
            return (
              <Link
                key={item.id}
                className="mobile-menu_item"
                to={`/${data.header.data.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}/${item.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}`}
              >
                <p className="">{item.attributes.title}</p>
              </Link>
            );
          })}
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className="main-odbor_container">
      <Helmet>
        <title>
          {data.header.data.attributes.title} | Súkromná základná umelecká škola
          Tvrdošín
        </title>{" "}
      </Helmet>
      {sidebar()}
      <div className="odbor-main_content">
        <div className="content_container">
          {topbar()}
          {/*breadcrumbs*/}
          <ul className="breadcrumbs">
            <li>
              <a href="/">{`Úvod >`}</a>
            </li>
            <li>
              <a
                href={`/${data.header.data.attributes.title
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replaceAll(" ", "-")}`}
              >
                {data.header.data.attributes.title}
              </a>
            </li>
          </ul>
          {/*breadcrumbs end*/}
          <div className="title_content">
            <h1 className="colorful odbor-header">
              {data.header.data.attributes.title}
            </h1>
            {items()}
            {content()}
          </div>
        </div>
        <div className="others_container">
          {photos()}
          {files()}
        </div>
      </div>
    </div>
  );
};

export default HeaderHeader;
