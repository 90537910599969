import "./Warning.css";
import Markdown from "markdown-to-jsx";
import X from "../../assets/x.svg";
import { useQuery, gql } from "@apollo/client";
import File from "./File/File";
import WarningCookie from "./WarningCookie";

const Warning = (props) => {

  const GET_LOCATIONS = gql`
    {
      warning {
        data {
          attributes {
            updatedAt
            title
            content
            files {
              __typename
              ... on ComponentFileFile {
                name
                file(pagination: { pageSize: 10000 }) {
                  data {
                    id
                    attributes {
                      size
                      name
                      ext
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;


  const files = () => {
    if (data.warning.data.attributes.files.length !== 0) {
      return data.warning.data.attributes.files.map((file, index) => {
        return (
          <div className="file file-warning" key={index}>
            <h2 className="h3-files">{file.name}</h2>
            <File data={file.file} color="fff" />
          </div>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <div style={{ position: "absolute" }}>
      <WarningCookie setCookieState={props.setCookieState} setCookie={props.setCookie} data={data.warning.data.attributes.updatedAt} />
      <div
        className={
          props.cookieState
            ? " warning_container warning_container_closed"
            : "warning_container"
        }
      >
        <div className="warning_message">
          <div
            className="warning-close_div"
            onClick={() => {
              props.setCookieState(true);
              props.setCookie("closed", true, { path: "/" });
            }}
          >
            <img className="warning-close" src={X} alt="zatvoriť" />
          </div>
          <h3>{data.warning.data.attributes.title}</h3>
          <div className="warning-overflow-container">
            <Markdown className="warning-text">
              {data.warning.data.attributes.content || "A"}
            </Markdown>
            {files()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warning;
