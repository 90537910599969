import "./File.css";
import Badge from "./Badge/Badge";
import ArrowDown from "../../assets/arrow-down.svg";
import { memo, useState } from "react";

const File = (props) => {
  // array of files that are already sorted *loaded by default so files show up*
  const [sortedArray, setSortedArray] = useState(props.data.data);
  // Ascending or descending order of files in size
  const [sizeFilter, setSizeFilter] = useState(true);
  // Ascending or descending order of files in name
  const [nameFilter, setNameFilter] = useState(true);
  // Sets filter that is currently loaded so the used filter is highligted
  const [currentFilter, setCurrentFilter] = useState("size");
  // sets current search query
  const [searchQuery, setSearchQuery] = useState("");

  // Sorts files by search query
  const search = (query) => {
    setSearchQuery(query);
    setSortedArray(
      props.data.data.filter((post) => {
        if (query === "") {
          return post;
        } else if (
          post.attributes.name.toLowerCase().includes(
            query
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
        ) {
          return post;
        }
      })
    );
  };

  // sorting by size
  const sortBySize = (upDown) => {
    let placeholderArray = [...props.data.data];
    if (upDown === false) {
      setSortedArray(
        placeholderArray.sort((a, b) => {
          return b.attributes.size - a.attributes.size;
        })
      );
    }
    if (upDown === true) {
      setSortedArray(
        placeholderArray.sort((a, b) => {
          return a.attributes.size - b.attributes.size;
        })
      );
    }
  };
  // sorting by size end

  // sorting by name (first letter of file name)
  const sortByName = (upDown) => {
    let placeholderArray = [...props.data.data];
    if (upDown === false) {
      setSortedArray(
        placeholderArray.sort((a, b) => {
          return b.attributes.name.localeCompare(a.attributes.name);
        })
      );
    }
    if (upDown === true) {
      setSortedArray(
        placeholderArray.sort((a, b) => {
          return a.attributes.name.localeCompare(b.attributes.name);
        })
      );
    }
  };
  // sorting by name (first letter of file name) end

  // sort by filetype
  const sortByFiletype = () => {
    let placeholderArray = [...props.data.data];
    setSortedArray(
      placeholderArray.sort((a, b) => {
        return a.attributes.ext.localeCompare(b.attributes.ext);
      })
    );
  };
  // sort by filetype end
  // if search return is empty

  const noResults = () => {
    if (sortedArray.length > 0) {
      return sortedArray.map((item, index) => {
        return <Badge color={props.color} key={index} data={item} />;
      });
    } else if (sortedArray.length === 0 && searchQuery !== "") {
      return <p className="no-files-found">Žiadne súbory neboli nájdené</p>;
    }
  };
  return (
    <div>
      <div className="file-sorter-container">
        <p className="filter-file-header">Filtovať:</p>
        <div className="file-sorter">
          <p
            onClick={() =>
              sortByName(!nameFilter) ||
              setNameFilter(!nameFilter) ||
              setCurrentFilter("name")
            }
            className={`sorting-button ${
              currentFilter === "name"
                ? "sorting-button-active"
                : "sorting-button-hover"
            }`}
          >
            Podľa názvu
            <img
              src={ArrowDown}
              alt=""
              className={`file-sorter-arrow ${
                nameFilter ? "file-sorter-arrow-up" : ""
              }`}
            />
          </p>
          {/* by size */}
          <p
            className={`sorting-button ${
              currentFilter === "size"
                ? "sorting-button-active"
                : "sorting-button-hover"
            }`}
            onClick={() =>
              sortBySize(!sizeFilter) ||
              setSizeFilter(!sizeFilter) ||
              setCurrentFilter("size")
            }
          >
            Podľa veľkosti
            <img
              src={ArrowDown}
              alt=""
              className={`file-sorter-arrow ${
                sizeFilter ? "file-sorter-arrow-up" : ""
              }`}
            />
          </p>
          {/* by size end */}
          <p
            className={`sorting-button ${
              currentFilter === "filetype"
                ? "sorting-button-active"
                : "sorting-button-hover"
            }`}
            onClick={() => sortByFiletype() || setCurrentFilter("filetype")}
          >
            Podľa typu
          </p>
        </div>
        <div className="file-search">
          <input
          role="search"
            type="text"
            placeholder="Hľadať medzi súbormi..."
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>
      <ul className="file-container">{noResults()}</ul>
    </div>
  );
};
export default memo(File);
