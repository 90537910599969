import TeacherCard from "../TeacherCard/TeacherCard";
import { motion } from "framer-motion";
import arrowDown from "../../../assets/arrow-down.svg";
import { useState } from "react";
const OdborCardSmall = (props) => {
  const [open, setOpen] = useState(false);
  const variants = {
    borderSmall: {
      borderRadius: "12px",
    },
    borderLarge: {
      borderRadius: "24px",
    },
    openArrow: {
      rotate: "180deg",
    },
    closeArrow: {
      rotate: "0deg",
    },
    openTeacher: {
      display: "block",
      height: "100%",
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
    closedTeacher: {
      height: "0",
      opacity: 0,
      transitionEnd: { display: "none" },
      transition: {
        staggerChildren: 0.2,
          when: "afterChildren",
          staggerDirection: -1,
      },
    },
  };
  return (
    <motion.div
      className="odbor-predmet-small-container"
      variants={variants}
      animate={open ? "borderLarge" : "borderSmall"}
    >
      <div className="odbor-predmet-small" onClick={() => setOpen(!open)}>
        <p className="odbor-predmet-small-header">
          {props.data.attributes.predmet}
        </p>
        <motion.img
          variants={variants}
          animate={open ? "openArrow" : "closeArrow"}
          onClick={() => setOpen(!open)}
          src={arrowDown}
          className="arrow-odbor"
          alt="sípka dole"
          initial="closeArrow"
        />
      </div>
      <motion.div
        variants={variants}
        animate={open ? "openTeacher" : "closedTeacher"}
        initial="closedTeacher"
        className="odbor-predmet-teachers-container"
      >
        {props.data.attributes.teachers.data.map((item) => {
          return (
            <TeacherCard
              key={item.id}
              open={open}
              setOpen={setOpen}
              data={item}
            />
          );
        })}
      </motion.div>
    </motion.div>
  );
};
export default OdborCardSmall;
