import "./Card.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
const Card = (props) => {
  const [hoveringCard, setHoveringCard] = useState("odbor-card_image");
  let font = "";

  if (props.data.attributes.title === "Literárno-dramatický odbor") {
    font = "24px";
  }
  return (
    <Link
      key={props.data.id}
      to={`/odbor/${props.data.attributes.title
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replaceAll(" ", "-")}`}
      onMouseEnter={() =>
        setHoveringCard("odbor-card_image_hover odbor-card_image")
      }
      onMouseLeave={() => setHoveringCard("odbor-card_image")}
      className="odbor-card"
    >
      <div className="odbor-card_header">
        <p style={{ fontSize: `${font}` }} className="odbor-card_title">
          {props.data.attributes.title}
        </p>
      </div>
      <img
      style={{animationDelay: props.index / Math.PI + "s"}}
        loading="lazy"
        className={hoveringCard}
        src={`${process.env.REACT_APP_API}${props.data.attributes.Image.data.attributes.url}?quality=50&format=webp&height=300`}
        alt={props.data.attributes.Image.data.attributes.alternativeText || "Obrázok"}
      />
    </Link>
  );
};
export default Card;
