import News from "./News";
import { useQuery, gql } from "@apollo/client";
import { motion } from "framer-motion";
import { memo } from "react";

const NewsContainer = () => {
  const GET_LOCATIONS = gql`
    {
      blogs(pagination: { start: 0, limit: 5 }, sort: "createdAt:desc") {
        data {
          id
          attributes {
            title
            slug
            type
            Cover {
              data {
                attributes {
                  url
                }
              }
            }
            createdAt
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;

  return (
    <div className="news-container-big">
      <motion.div className="news-container">
        <div className="card-parent">
          <div className="card-info">
            <h2 style={{ fontWeight: 900, margin: 0 }}>
              Aktuality a<br />
              novinky
            </h2>
          </div>
        </div>
        {data.blogs.data.map((item) => {
          
          return <News key={item.id} data={item} />
        })}
      </motion.div>
    </div>
  );
};

export default memo(NewsContainer);
