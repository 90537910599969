import heart from "../../../assets/heart.svg";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
const LikedSheetMusic = ({ id }) => {
  const [liked, setLiked] = useState();
  const USER_REGISTRATION = gql`
    mutation query($noties: [ID]) {
      updateUsersPermissionsUser(id: 61, data: { noties: $noties }) {
        data {
          id
        }
      }
    }
  `;
  const [createRegistration] = useMutation(USER_REGISTRATION, {
    variables: {
      noties: id,
    },
  });

  return (
    <div
      onClick={() =>   createRegistration()}
      className="button-sheet-music-left justify-content no-margin-right no-margin-bottom"
    >
      <p>Uložiť</p>
      <img src={heart} alt="stiahnuť súbor" />
    </div>
  );
};
export default LikedSheetMusic;
