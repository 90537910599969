import "../Blog/Blog.css";
import { Link, useParams } from "react-router-dom";
import File from "../File/File";
import Markdown from "markdown-to-jsx";
import Photo from "../Photo/Photo";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { motion, useViewportScroll } from "framer-motion";
import Video from "../Video/Video";

const Custom = () => {
  const { scrollYProgress } = useViewportScroll();
  let { slug } = useParams();

  const GET_LOCATIONS = gql`
    query CustomUrl {
      customs(filters: { url: { eq: "${slug}" } }) {
        data {
          attributes {
            title
            content
            files {
              __typename
              ... on ComponentFileFile {
                name
                file(pagination: { pageSize: 10000 }) {
                  data {
                    attributes {
                      name
                      size
                      url
                      ext
                    }
                  }
                }
              }
            }
            pictures {
              data {
                attributes {
                  url
                }
              }
            }
            videos {
              data {
                id
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);
  if (loading) return null;
  if (error) return <p>Error : {error}</p>;

  const files = () => {
    if (data.customs.data[0].attributes.files === null) {
      return;
    } else {
      return data.customs.data[0].attributes.files.map((item, index) => {
        return (
          <div className="file" key={index}>
            <h2 className="h3-files">{item.Name}</h2>
            <File data={item.file} />
          </div>
        );
      });
    }
  };
  const photos = () => {
    if (
      data.customs.data[0].attributes.pictures.data.length === 0 &&
      data.customs.data[0].attributes.videos.data.length === 0
    ) {
      return;
    } else {
      return (
        <div className="photo-container_main">
          <h2 className="h3-files">Fotky:</h2>
          <div className="photo-container">
            <Photo data={data.customs.data[0].attributes.pictures.data} />
            <Video data={data.customs.data[0].attributes.videos.data} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="main-odbor_container">
      <svg className="svg-blog">
        <defs>
          <linearGradient id="e" gradientUnits="userSpaceOnUse">
            <stop stopColor="#f15a24" offset="0" />
            <stop stopColor="#c52b2c" offset="1" />
          </linearGradient>
        </defs>
        <motion.line
          className="line-blog"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          stroke="url(#e)"
          style={{ pathLength: scrollYProgress }}
        />
      </svg>
      <Helmet>
        <title>{data.customs.data[0].attributes.title} | Szuš Tvrdošín </title>
      </Helmet>
      <div className="odbor-main_content">
        <div className="content_container">
          <ul className="breadcrumbs">
            <li>
              <Link to="/">{"Úvod >"}</Link>
            </li>
            <li>
              <Link to="/aktuality">{"Blog "}</Link>
            </li>
          </ul>

          <article className="title_content">
            <h1 className="colorful odbor-header">
              {data.customs.data[0].attributes.title}
            </h1>
            <Markdown className="blog-text">
              {data.customs.data[0].attributes.content}
            </Markdown>
          </article>
        </div>
        <div className="others_container">
          {photos()}
          {files()}
        </div>
      </div>
    </div>
  );
};
export default Custom;
