import "./Blog.css";
import Type from "../News/Type/Type";
import File from "../File/File";
import Markdown from "markdown-to-jsx";
import { NavLink, useParams } from "react-router-dom";
import Photo from "../Photo/Photo";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { motion, useViewportScroll } from "framer-motion";
import Video from "../Video/Video";

const Blog = () => {
  const { scrollYProgress } = useViewportScroll();
  let { slug } = useParams();
  const GET_LOCATIONS = gql`
    {
      blogSidebarData: blogs(
        pagination: { start: 0, limit: 4 }
        sort: "createdAt:desc"
      ) {
        data {
          id
          attributes {
            title
          }
        }
      }
      blogs(filters: { slug: { eq: "${slug}" } }) {
        data {
          attributes {
            title
            content
            type
            pictures {
              data {
                id
                attributes {
                  url
                }
              }
            }
            videos {
              data {
                attributes {
                  url
                }
              }
            }
            files {
              __typename
              ... on ComponentFileFile {
                id
                name
                file(pagination: { pageSize: 10000 }) {
                  data {
                    id
                    attributes {
                      size
                      name
                      ext
                      url
                    }
                  }
                }
              }
            }
            createdAt
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return null;
  if (error) return <p>Error :</p>;
  const dateOld = Date.parse(data.blogs.data[0].attributes.createdAt);
  const files = () => {
    if (data.blogs.data[0].attributes.files === null) {
      return;
    } else {
      return data.blogs.data[0].attributes.files.map((item) => {
        return (
          <div className="file" key={item.id}>
            <h2 className="h3-files">{item.name}</h2>
            <File data={item.file} />
          </div>
        );
      });
    }
  };

  const sidebar = () => {
    return (
      <aside className="sidebar_container">
        <div className="sidebar">
          <h4 className="sidebar_item more_results colorful">
            Najnovšie príspevky
          </h4>
          {data.blogSidebarData.data.slice(-4).map((item) => {
            return (
              <div className="sidebar_item" key={item.id}>
                <NavLink
                  key={item.id}
                  className=" link"
                  to={`/blog/${item.attributes.title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}`}
                >
                  {item.attributes.title}
                </NavLink>
              </div>
            );
          })}
        </div>
      </aside>
    );
  };

  const topbar = () => {
    return (
      <div className="odbor-top">
        <p>
          Pridané: <time>{new Date(dateOld).toLocaleDateString("sk-SK")}</time>
        </p>
        <Type type={data.blogs.data[0].attributes.type} />
      </div>
    );
  };
  const photos = () => {
    if (
      data.blogs.data[0].attributes.pictures.data.length === 0 &&
      data.blogs.data[0].attributes.videos.data.length === 0
    ) {
      return;
    } else {
      return (
        <div className="photo-container_main">
          <h2 className="h3-files">Fotky:</h2>
          <div className="photo-container">
            <Photo data={data.blogs.data[0].attributes.pictures.data} />
            <Video data={data.blogs.data[0].attributes.videos.data} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="main-odbor_container">
      <svg className="svg-blog">
        <defs>
          <linearGradient id="e" gradientUnits="userSpaceOnUse">
            <stop stopColor="#f15a24" offset="0" />
            <stop stopColor="#c52b2c" offset="1" />
          </linearGradient>
        </defs>
        <motion.line
          className="line-blog"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
          stroke="url(#e)"
          style={{ pathLength: scrollYProgress }}
        />
      </svg>
      <Helmet>
        <title>{data.blogs.data[0].attributes.title} | Szuš Tvrdošín </title>
      </Helmet>
      {sidebar()}
      <div className="odbor-main_content">
        <div className="content_container">
          {topbar()}
          <ul className="breadcrumbs">
            <li>
              <a href="/">{"Úvod >"}</a>
            </li>
            <li>
              <a href="/aktuality">{"Blog "}</a>
            </li>
          </ul>

          <article className="title_content">
            <h1 className="colorful odbor-header">
              {data.blogs.data[0].attributes.title}
            </h1>
            <Markdown className="blog-text">
              {data.blogs.data[0].attributes.content}
            </Markdown>
          </article>
        </div>
        <div className="others_container">
          {photos()}
          {files()}
        </div>
      </div>
    </div>
  );
};
export default Blog;
