import { useCookies } from "react-cookie";
import { useEffect } from "react";
const WarningCookie = (props) => {
  const [cookies, setCookie] = useCookies(["warning"]);
  useEffect(() => {
    if (cookies.warning === props.data) {
    } else {
      setCookie("warning", props.data, {
        path: "/",
      });
      props.setCookieState(false);
      props.setCookie("closed", false, { path: "/" });
    }
  });
};

export default WarningCookie;
