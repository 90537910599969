import Main from "../Main/Main";
import NewsContainer from "../News/NewsCards/NewsContainer";
import Odbory from "../OdborCard/OdborCard";
import AboutBottom from "../AboutBottom/AboutBottom";
import BottomInfo from "../BottomInfo/BottomInfo";

const Home = () => {
  return (
    <div className="main_container_big">
      <Main key="902" />
      <NewsContainer key="9822" />
      <Odbory key="9892" />
      <AboutBottom key="9889" />
      <BottomInfo key="98899" />
    </div>
  );
};

export default Home;
